import React from 'react'
import {Link,Button,AppBar} from '@mui/material'
import {Link as RouterLink,useLocation} from 'react-router-dom'
import logo from '../resources/logo.jpeg'
import * as ROUTES from '../routes'
import CurrencyIndicator from './currencyIndicator'
import AuthBadge from './authBadge'
export default function NavBar(props){
   
    const location = useLocation()
  

    if(location.pathname.includes(ROUTES.ADMIN))
        return(
            <AppBar position = 'sticky' sx = {{alignItems:'center',display:'flex', flexDirection:'row',justifyContent:'space-between',bgcolor:'background.paper'}} >
            <span style = {{display:'flex',alignItems:'center',justifyContent:'space-evenly',flex:1}} >
                <span style = {{flex:1,marginRight:100}} item xs = {2}>
                <Link sx = {{marginRight: '20px'}}underline = 'hover' color = 'text.primary' variant = 'h4' component = {RouterLink} to = '/'>
                    <img alt = 'Homes-Real-Estate logo' style = {{height:'60px',alignSelf:'center'}} src = {logo}/>
                </Link>
                </span>
                <span style = {{flex:5}} >
                <Link sx = {{marginRight: '20px'}} underline = 'hover' color = 'text.primary' variant = 'h4' component = {RouterLink} to = {`${ROUTES.ADMIN}`}>
                        <Button  sx = {{color: 'text.primaryGreen'}} size = 'large' >Properties</Button>
                    </Link>
                    <Link sx = {{marginRight: '20px'}} underline = 'hover' color = 'text.primary' variant = 'h4' component = {RouterLink} to = {`${ROUTES.ADMIN}/${ROUTES.INQURIES}`}>
                        <Button  sx = {{color: 'text.primaryGreen'}} size = 'large' >Inquiries</Button>
                    </Link>
                    <Link sx = {{marginRight: '20px'}} underline = 'hover' color = 'text.primary' variant = 'h4' component = {RouterLink} to = {`${ROUTES.ADMIN}/${ROUTES.CMS}`}>
                        <Button  sx = {{color: 'text.primaryGreen'}} size = 'large' >Content Management</Button>
                    </Link>
                   
                 
                </span>
            </span>
            <AuthBadge />
        </AppBar>
        )

    return(
        <AppBar position = 'sticky' sx = {{alignItems:'center',display:'flex', flexDirection:'row',justifyContent:'space-between',bgcolor:'background.paper'}} >
            <span style = {{display:'flex',alignItems:'center',justifyContent:'space-evenly'}} >
                <span style = {{flex:1,marginRight:100}} item xs = {2}>
                <Link sx = {{marginRight: '20px'}}underline = 'hover' color = 'text.primary' variant = 'h4' component = {RouterLink} to = '/'>
                    <img alt = 'Homes-Real-Estate logo' style = {{height:'60px',alignSelf:'center'}} src = {logo}/>
                </Link>
                </span>
                <span style = {{flex:4}} item spacing = {2} xs = {10}>
                    <Link sx = {{marginRight: '20px'}}underline = 'hover' color = 'text.primary' variant = 'h4' component = {RouterLink} to = {ROUTES.PROPERTIES}>
                        <Button sx = {{color: 'text.primaryGreen'}} size = 'large' >Market</Button>
                    </Link>
                    <Link sx = {{marginRight: '20px'}} underline = 'hover' color = 'text.primary' variant = 'h4' component = {RouterLink} to = {ROUTES.SELL}>
                        <Button  sx = {{color: 'text.primaryGreen'}} size = 'large' >Sell</Button>
                    </Link>
                    
                 
                </span>
            </span>
            {
                location.pathname.includes(ROUTES.HOME) || location.pathname.includes(ROUTES.PROPERTIES) || location.pathname === ROUTES.ROOT?
            <CurrencyIndicator /> : null
            }
        </AppBar>
    )
}

