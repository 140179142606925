import { useState,useEffect,useRef } from 'react'
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import CSSBaseLine from '@mui/material/CssBaseline'
import {Container} from '@mui/material'
import {ThemeProvider} from '@mui/material/styles'
import Navbar from './components/NavBar'
import theme from './styles/theme'
import HomeScreen from './screens/home'
import SellScreen from './screens/sellProperty'
import Footer from './components/footer'
import NotFound from './screens/notFound'
import PropertyDetailScreen from './screens/propertyDetail'
import MarketPage from './screens/market'
import ContactScreen from './screens/contact'
import AdminHome from './screens/adminHome'
import EditPropertyAdminScreen from './screens/editPropertyAdmin'
import { AuthContext } from './authContext'
import  AdminInquiries  from './screens/adminInquieries'
import AdminLogin from './screens/adminLogin'
import RequireAuth from './requireAuth'
import AdminCMS from './screens/adminCMS'
import NewPropertyForm from './components/adminComponents/newPropertyComponent'
import * as ROUTES from './routes'
import {currencies,CurrencyContext} from './currencyContext'
import {units,LandSizeUnitContext} from './landSizeUnitContext'
import {ContactContext,initialContact} from './contactContext'
import {getSocials} from './backend/FB_API'
import setStateAsync from './backend/setStateAsync'
import logo from './resources/logo.jpeg'





function App() {
  const height = window.screen.availHeight
  const [currency,setCurrency] = useState(currencies[0])
  const [unit,setUnit] = useState(units[0])
  const [contactInfo,setContactInfo] = useState(initialContact)
  const [loading,toggleLoading] = useState(false)
  const mounted = useRef(true)

  useEffect(()=>{
    getData()

    return ()=> mounted.current = false
  },[])
  
  async function getData(){
    try{
      setStateAsync(true,toggleLoading,mounted.current)
      const socials = await getSocials()
      setContactInfo(socials)
    }catch(e){
      ;
    }finally{
      setStateAsync(false,toggleLoading,mounted.current)
    }
  }

  if(loading)
    return(
      <div style = {{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh'}}>
        <img alt = 'logo' src = {logo} width = '50%' />
      </div>
    )

  return (
   
    <BrowserRouter >
    <ThemeProvider theme = {theme}>
      <CurrencyContext.Provider value = {{
       value: currency,
       changeCurrency: newVal=> setCurrency(newVal)
        }} >
      <LandSizeUnitContext.Provider value = {{
        value: unit,
        changeUnit: newUnit=> setUnit(newUnit)
      }} >
      <ContactContext.Provider value = {{
        value: contactInfo,
        changeValue: newVal=> setContactInfo(newVal)
        }}>
          <AuthContext>
        <CSSBaseLine />
        <Navbar />
        <Container disableGutters maxWidth = 'xl' component = 'div'  sx = {{minHeight:`${height}px`}}>
        <Routes >
          <Route path = {ROUTES.ROOT} element = {<HomeScreen/>} />
          <Route path = {ROUTES.HOME} element = {<HomeScreen/>} />
          <Route path = {ROUTES.SELL} element = {<SellScreen/>} />
          <Route path = {ROUTES.CONTACT} element = {<ContactScreen />}/>
          <Route path = {ROUTES.PROPERTIES} element = {<MarketPage />} />
          <Route path = {ROUTES.PROPERTIES + '/:propertyID'} element = {<PropertyDetailScreen/>}/>
          <Route path = {ROUTES.LOGIN} element = {<AdminLogin />} />
          
          <Route path = {ROUTES.ADMIN} element = {<RequireAuth >
            <AdminHome />
            </RequireAuth>} />
          <Route path = {`${ROUTES.ADMIN}/${ROUTES.EDITPROPETY}/:propertyID`} element = {<RequireAuth >
          <EditPropertyAdminScreen/>
          </RequireAuth>}/>
          <Route path = {`${ROUTES.ADMIN}/${ROUTES.NEWPROPERTYFORM}/`} element = {<RequireAuth >
          <NewPropertyForm/>
          </RequireAuth>}/>
          <Route path = {`${ROUTES.ADMIN}/${ROUTES.INQURIES}`} element = {<RequireAuth >
          <AdminInquiries/>
          </RequireAuth>}/>
          <Route path = {`${ROUTES.ADMIN}/${ROUTES.CMS}`} element = {<RequireAuth >
          <AdminCMS/>
          </RequireAuth>}/>
          
          <Route path = '*' element = {<NotFound/>} /> 
          
        </Routes>
        </Container>
        <Footer />
        </AuthContext>
        </ContactContext.Provider>
        </LandSizeUnitContext.Provider>
        </CurrencyContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
