import React from 'react'
import {Stepper,MobileStepper,useMediaQuery,useTheme} from '@mui/material'

export default function ResponsiveStepper(props){
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    if(isMobile)
        return(
            <MobileStepper {...props}>
                {props.children}
            </MobileStepper>
        )

    return(
        <Stepper {...props}>
            {props.children}
        </Stepper>
    )
}