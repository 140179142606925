import React, { useState,useEffect,useRef } from 'react'
import { Button,Grid,ButtonGroup } from '@mui/material'
import {useNavigate} from 'react-router-dom'
import * as ROUTES from '../../routes'
import DeleteIcon from '@mui/icons-material/Delete';
import PropertyThumbnail from './propertyThumbnailAdmin'
import Card from '../Card'
import {getProperties,deleteProperty,putProperty} from '../../backend/api'
import setStateAsync from '../../backend/setStateAsync'
import LoadingComponent from '../loadingComponent'
import ErrorComponent from '../errorComponent'


export default function ApprovedProperties(props){

    const [loading,toggleLoading] = useState(true)
    const [error,toggleError] = useState(false)
    const [properties,setProperties] = useState([])
    const navigate = useNavigate()
    const mounted = useRef(true)

    useEffect(()=>{
            mounted.current = true
            getData()
       

        return ()=> mounted.current = false
    },[])
    async function getData(){
        try{
            setStateAsync(true,toggleLoading,mounted.current)
            setStateAsync(false,toggleError,mounted.current)
            const data = await getProperties({approved:true})
            setStateAsync(data,setProperties,mounted.current)
        }catch(e){
            setStateAsync(true,toggleError,mounted.current)
        }finally{
            setStateAsync(false,toggleLoading,mounted.current)
        }
    }

    async function deletePropertyFunc(propertyID){
        try{    
        
            await deleteProperty(propertyID)
            await getData()
            const tempProperties = properties.filter(props=> props.propertyID !== propertyID)
            setProperties(tempProperties)
        }catch(e){
            console.log(e)
        }

    }

    if(loading)
        return(
            <LoadingComponent />
        )
    if(error)
        return(
            <ErrorComponent errorText = 'Could not get properties' onClick = {()=> getData()} />
        )

        async function putPropertyManager(property){
            try{
                
                const newProperty = JSON.parse(JSON.stringify(property))
                newProperty.enabled = !property.enabled
                delete newProperty.propertyID
                const newProp = await putProperty(newProperty,property.propertyID)
                const tempProperties = properties.map(prop=>{
                    if(prop.propertyID === property.propertyID)
                        return newProp
                    else
                        return prop
                })
                setStateAsync(tempProperties,setProperties,mounted.current)
            }catch(e){
                alert('error approving property')
            }
            
        }

    return(
        <Card style = {{padding:5,marginTop:15}}>
            <h2 style = {{marginTop:15,marginBottom:15,textAlign:'center'}}>Current Properties</h2>
            { !properties?.length > 0 ? 
            <div >
            <h2 style = {{textAlign:'center'}}>No Properties</h2>
            </div> :
            <Grid container spacing = {3}>
            {
            properties?.length === 0 ? 
            <div>
                <h2>No Properties</h2>
            </div> : properties?.map((property,index)=>
                <Grid key = {index} item xs = {12} md = {4} lg = {3} sx = {{display:'flex',flexDirection:'column',alignItems:'center'}}> 
                    <PropertyThumbnail item = {property} >
                        <ButtonGroup sx = {{alignSelf:'center'}}>
                            <Button onClick = {()=> navigate(`${ROUTES.EDITPROPETY}/${property?.propertyID}`) } sx = {{alignSelf:'center'}}>Edit</Button>
                            <Button onClick = {()=> deletePropertyFunc(property?.propertyID)}> <DeleteIcon /></Button>
                            <Button onClick = {()=> putPropertyManager(property)}> {property?.enabled ? 'Disable' : 'Enable'}</Button>
                    </ButtonGroup>
                    </PropertyThumbnail>
                    
                </Grid>
            )}
            </Grid> }
        </Card>
    )
}