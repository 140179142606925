import React,{useContext} from 'react'
import {CurrencyContext} from '../../currencyContext'
import {useNavigate} from 'react-router-dom'
import BedIcon from '@mui/icons-material/Bed';
import BathroomIcon from '@mui/icons-material/Bathroom';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SlideShow from '.././slideShow';
import Divider from '.././Divider';
import Card from '.././Card';
import * as ROUTES from '../../routes'


/**
 * Displays thumbnail of property to be used in various places.
 * Uses slideshow component.
 * @param {item:{images,beds,baths,size,location,parking}} props 
 * @returns React component
 */
function PropertyThumbnail(props){

    const currency = useContext(CurrencyContext).value
    const item = props.item
    const images = item?.images
    const beds = item?.beds
    const baths = item?.baths
    const size = item?.size
    const address = item?.address
    const city = item?.city
    const AC = item?.parking
    const price = item?.price * currency.value
    const type = item?.type
    const ID = item?.propertyID
    const sold = item?.sold
    const soldPrice = item?.soldPrice ? item?.soldPrice * currency.value : null
    const approved = item?.approved
    
  
    const navigate = useNavigate()


    function onClick(){
        navigate(`../${ROUTES.PROPERTIES}/${ID}`)
    }


    return(
        <Card  style = {{backgroundColor: 'background.lightSecondary', borderRadius:2,position:'relative',paddingTop:1,display:'flex',flexDirection:'column',alignItems:'center'}}>
            <div style={{display:'flex',flexDirection:'column',cursor:'pointer'}}>
                { approved ?
            <SlideShow 
            images = {images}
            onClick = {()=> onClick()}
            imgStyle = {{height:'auto'}}
            /> : 
                    <span style = {{padding:10}}>
                        <h3 style = {{textAlign:'center'}}>Submitted by: {item?.owner_info?.first_name + ' ' + item?.owner_info.last_name}</h3>
                        <h3 style = {{textAlign:'center'}} >Email: {item?.owner_info.email}  </h3>
                        <h3 style = {{textAlign:'center'}} >Phone: {item?.owner_info?.phone_number}</h3>
                    </span>
            }
            { sold &&
            <span style = {{width:'100%',backgroundColor:'green'}}>
                <h2 style = {{color:'white', textAlign:'center',margin:0}}>SOLD: {soldPrice?.toLocaleString('en-US',{style:'currency',currency:currency.name})}</h2>
            </span>
            }
                <div  style = {{padding:5}} onClick = {()=> onClick()}>
                    
                    <h4 style={{textAlign:'center',margin:0}}>{price.toLocaleString('en-US',{style:'currency',currency:currency.name})}{type === 'rental' ? ' /mo.' : null}</h4>
                    <Divider />
                    <span style = {{display:'flex', padding:2}} alignItems = 'center' container spacing = {2} sx = {{px:1}}>
                        <span style = {styles.gridStyle}>
                            <p style = {{margin:0,fontSize:'13px'}}>{<BedIcon fontSize = '10px' />} {beds} Bed</p>
                        </span>
                        <span style = {styles.gridStyle} direction = 'row' item xs = 'auto'>
                            <p style = {{margin:0,fontSize:'13px'}}>{<BathroomIcon fontSize = '10px' />} {baths} Bath</p>
                        </span>
                        <span style = {styles.gridStyle} direction = 'row' item xs = 'auto'>
                            <p style = {{margin:0,fontSize:'13px'}}>{<ArchitectureIcon fontSize = '10px' />} {size} acres</p>
                        </span>
                        {
                            AC ? 
                            <span style = {styles.gridStyle} direction = 'row' item xs = {1}>
                            <AcUnitIcon />
                            </span> : null
                        }
                        
                    </span>
                <Divider />
                <p style = {{margin:0,textAlign:'center'}}>{address},{city}</p>
                <Divider style = {{marginBottom:2}} />
                </div>
            </div>
          {props.children}
        </Card>
    )
}

export default React.memo(PropertyThumbnail)

const styles = {
    gridStyle:{
        display:'flex',
        flex:1,
        flexShrink:1,
        justifyContent:'space-evenly',
        alignItems:'center'
    }
}