import React from 'react'
import {Grid} from '@mui/material'
import PropertyThumbnail from '../components/propertyThumbnail'
import EmptyPropertys from './emptyPropertys'



 function FeaturedRentals(props){



   const featuredProperties = props.properties ? props.properties : []
   



    return(
        
            featuredProperties.length > 0 ?
        <Grid container spacing = {3}>
            {  featuredProperties.map(property=>
                <Grid item xs = {12} md = {4} lg = {3}> 
                    <PropertyThumbnail item = {property} /> 
                </Grid>)  
            }
        </Grid> : <EmptyPropertys />
        
        
    )
}

export default React.memo(FeaturedRentals)