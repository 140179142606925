import React, { useState,useEffect,useRef } from 'react'
import { Button,Grid } from '@mui/material'
import {useNavigate} from 'react-router-dom'
import * as ROUTES from '../../routes'
import PropertyThumbnail from './propertyThumbnailAdmin'
import Card from '../Card'
import {getPendingProperties} from '../../backend/api'
import setStateAsync from '../../backend/setStateAsync'
import LoadingComponent from '../loadingComponent'
import ErrorComponent from '../errorComponent'


export default function PendingProperties(props){

    const [loading,toggleLoading] = useState(true)
    const [error,toggleError] = useState(false)
    const [properties,setProperties] = useState([])
    const navigate = useNavigate()

    const mounted = useRef(true)

    useEffect(()=>{
        setTimeout(()=>{
            getData()
        },1000)

        return ()=> mounted.current = false
    },[])
    async function getData(){
        try{
            setStateAsync(true,toggleLoading,mounted.current)
            setStateAsync(false,toggleError,mounted.current)
            const data = await getPendingProperties()
            setStateAsync(data,setProperties,mounted.current)
        }catch(e){
            setStateAsync(true,toggleError,mounted.current)
        }finally{
            setStateAsync(false,toggleLoading,mounted.current)
        }
    }

    if(loading)
        return(
            <LoadingComponent />
        )
    if(error)
        return(
            <ErrorComponent errorText = 'Could not get properties' 
            onClick = {()=> getData()} />
        )

    return(
        <Card style = {{padding:5,marginTop:15}}>
            <h2 style = {{margin:0,textAlign:'center'}}>New Submissions</h2>
            { !properties?.length > 0 ? 
            <div >
            <h2 style = {{textAlign:'center'}}>No Properties</h2>
            </div> :
            <Grid container spacing = {3}>
            
            {
            properties?.map((property,index)=>
                <Grid item xs = {12} md = {4} lg = {3} sx = {{display:'flex',flexDirection:'column',alignItems:'center'}}> 
                    <PropertyThumbnail item = {property} >
                    <Button onClick = {()=> navigate(`${ROUTES.EDITPROPETY}/${property?.propertyID}`)} sx = {{alignSelf:'center'}}>Review and Approve</Button>
                    </PropertyThumbnail>
                    
                </Grid>
            )}
            </Grid> 
    }
        </Card>
    )
}