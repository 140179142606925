import React,{useState,useEffect,useRef} from 'react'
import {Container,Grid} from '@mui/material'
import setStateAsync from '../backend/setStateAsync'
import {getPropertyInquiries} from '../backend/api'
import LoadingComponent from '../components/loadingComponent'
import ErrorScreen from '../components/errorScreen'
import PropertyInquieryThumbnail from '../components/adminComponents/propertyInquieryThumbnail'
import Divider from '../components/Divider'


export default function AdminInquiries(){
    const [inquireies,setInquiries] = useState([])
    const [loading,toggleLoading] = useState(true)
    const [error,toggleError] = useState(false)
    const mounted = useRef(true)

    useEffect(()=>{
        getData()
        return ()=> mounted.current = false
    },[])

    async function getData(){
        try{    
            setStateAsync(true,toggleLoading,mounted.current)
            setStateAsync(false,toggleLoading,mounted.current)
            const data = await getPropertyInquiries()
            setStateAsync(data,setInquiries,mounted.current)
        }catch(e){
            setStateAsync(true,toggleError,mounted.current)
        }finally{
            setStateAsync(false,toggleLoading,mounted.current)
        }
    }

    function changeInquiery(newInquiery){
        const newInquieries = inquireies.map(inq=>{
            if(inq.inquieryID === newInquiery.inquieryID)
                return newInquiery
            else
                return inq
        })
        setInquiries(newInquieries)
    }
return(
    <Container disableGutters maxWidth = 'xl' sx = {{backgroundColor:'bg.paper',px:2}} >
        
        <h2 style = {{textAlign:'center'}}>Property Inquiries</h2>
        <Divider style = {{width:'100%'}} />
        {loading ? <LoadingComponent /> :error ? 
        <ErrorScreen errorText = 'Error Loading Inquiries' onClick = {()=> toggleError(false)} /> :
        <Grid rowSpacing={2} columnSpacing={2} align = 'center' container>
           { inquireies.map(inquiery=> 
           <Grid item xs = {12} md = {4}>
                <PropertyInquieryThumbnail setInquiries = {changeInquiery} inquiery = {inquiery} />
           </Grid>
           ) }
        </Grid>
        
         }
    </Container>
)
}
