import React,{useState} from 'react'

export default function HoverableDiv(props){
    const [hover,toggleHover] = useState(false)

    function onClick(){
        if(props.onClick)
            props.onClick()
    }
    return(
        <span onMouseEnter={()=> toggleHover(true)} onMouseLeave={()=> toggleHover(false)}  onClick = {()=> onClick()} style = {{...props.style, backgroundColor: hover ? '#212121B3' : 'transparent'  }}>
            {props.children}
        </span>
    )
}