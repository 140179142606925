import React from 'react'
import {Container,Typography} from '@mui/material'
import PropertyForm from '../components/submitPropertyForm'

export default function SellPropertyScreen(props){

    return(
        <Container disableGutters maxWidth = '100%' component = 'div' sx = {{padding:5,alignContent:'center',display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <Typography variant = 'h5' textAlign = 'center' mb = {2}>Complete the form below and await approval</Typography>
            <PropertyForm />
        </Container>
    )
}