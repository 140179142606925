
export default function parseYoutubeID(youtubeURL){
    try{
   
    const videoStart = 'v='
    const videoEnd = '&'

    let startIndex = 0
    let endIndex = 0
   
    let queryReached = false

       
    for(let i = 0; i < youtubeURL.length; i++){

        if(queryReached && youtubeURL[i] === videoEnd){
            endIndex = i
            continue
        }else if(queryReached)
            continue
            

        if(!queryReached && youtubeURL[i] + youtubeURL[i +1] === videoStart){
            queryReached = true
            startIndex = i + 2
            endIndex = youtubeURL.length 
        }

    }
   
    const videoID = youtubeURL.slice(startIndex,endIndex)

    return videoID




    }catch(e){
        console.log(e)
        return ''
    }

    

}