import React from 'react'
import {Container,Button} from '@mui/material'
import PendingProperties from '../components/adminComponents/pendingProperties'
import ApprovedProperties from '../components/adminComponents/approvedProperties'
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from 'react-router-dom'
import * as ROUTES from '../routes'

export default function AdminHome(props){
    const navigate = useNavigate()
    return(
        <Container disableGutters maxWidth = 'xl' sx = {{backgroundColor:'bg.paper'}} >
            <div style = {{width:'100%', display:'flex', justifyContent:'center',margin:10}}>
            <Button style = {{alignSelf:'center'}} onClick = {()=> navigate(ROUTES.NEWPROPERTYFORM) } > <AddIcon /> New Property </Button>
            </div>
            <PendingProperties />
            <ApprovedProperties />
        </Container>
    )
}