import {createContext} from 'react'

export const initialContact = {
    
        FB_LINK: 'https://facebook.com',
        IG_LINK: 'https://instagram.com',
        LinkedIN_LINK:'https://Linkedin.com',
        address:"140 Independence Street, Roseau.",
        email:"choicerealestateda@gmail.com",
        phone:"7672450110"
    
    
}

export const ContactContext =  createContext({
    value: initialContact,
    changeValue : ()=> {}
})