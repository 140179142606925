import React,{useState,useEffect,useRef,useCallback} from 'react'
import {Container} from '@mui/material'
import {useParams} from 'react-router-dom'
import EditPropertyForm from '../components/adminComponents/editPropertyForm'
import setStateAsync from '../backend/setStateAsync'
import {getProperty} from '../backend/api'
import LoadingComponent from '../components/loadingComponent'
import ErrorScreen from '../components/errorScreen'

export default function EditPropertyAdminScreen(props){
    const params = useParams()
    const propertyID = params.propertyID

    const [loading,toggleLoading] = useState(true)
    const [property,setProperty] = useState(null)
    const [error,toggleError] = useState(false)
    const mounted = useRef(true)

    const getData = useCallback(async ()=>{
        try{
        
            setStateAsync(true,toggleLoading,mounted.current)
            setStateAsync(false,toggleError,mounted.current)
            const property = await getProperty(propertyID)
            setStateAsync(property,setProperty,mounted.current)
        }catch(e){
           
            setStateAsync(true,toggleError,mounted.current)
        }finally{
          
            setStateAsync(false,toggleLoading,mounted.current)
        }
    },[propertyID])

    useEffect(()=>{
        mounted.current = true
        getData()

        return ()=> mounted.current = false
    },[getData])

    if(loading)
        return(
            <LoadingComponent />
        )
    if(error)
        return(
            <ErrorScreen
            errorText = 'Error Fetching property'
            buttonText = 'Try again'
            onClick = {()=> getData()}
            />
            )
    return(
        <Container disableGutters maxWidth = 'xl' >
            <EditPropertyForm property = {property} />
        </Container>
    )
}