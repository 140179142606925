import React from 'react'
import ContentManagementList from '../components/adminComponents/contentManagementList'
import {Container} from '@mui/material'

export default function AdminCMS(props){

    return(
        <Container disableGutters maxWidth = 'xl'>
            <ContentManagementList />
        </Container>
    )
}