import React,{useState} from 'react'
import {Grid} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';

export default function HoverAbleImg(props){
    const img = props.image
    const key = props.key
    const removeImage = props.removeImage
    const [hover,toggleHover] = useState(false)
    return(
        <Grid onMouseEnter = {()=>toggleHover(true)} onMouseLeave = {()=>toggleHover(false)}  sx = {{position:'relative',padding:0}} item xs = {6} md = {4} lg = {2}>
                    <img height = '100px' width = '100%' key = {key} src = {img.image} alt = 'submission'  />
                    <div  onClick = {()=> removeImage(img)} style = {{position:'absolute', bottom:0,left:0,zIndex:100,width:'100%',height:'100%',
                                     backgroundColor:'#212121B3',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',
                                     opacity: hover ? 1 : 0
                                     }}>
                        <ClearIcon />
                    </div>
        </Grid>
    )
}