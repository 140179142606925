import React,{useState,useCallback} from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Brightness1Icon from '@mui/icons-material/Brightness1';

/**
 * Displays list of imahes as a slide show.
 * No automatic scrolling.
 * Comes with its own indexing dots.
 * @param {images: List of images} props 
 * @returns React function
 */
 export default function SlideShow(props){

    const images = props.images
    const [currentItem,cycleItem] = useState(0)

    const styles = {
        chevronBox: {
            display: 'flex',
            paddingHorizontal:'10px', height: '100%', 
                    position: 'absolute',top:0,
                    alignItems: 'center',
                    flexDirection:'column',
                    justifyContent:'center', 
                    backgroundColor:'transparent'}
                    
        
    }

    function onClick(){
        if(props.onClick)
            props.onClick()
    }

    const decrementItem = useCallback(()=>{
        if(currentItem <= 0)
            cycleItem(images.length -1)
        else
            cycleItem(currentItem - 1)
    },[images.length,currentItem])

    //memoized hook used to incrememnt index of current slideshow item
    const incrementItem = useCallback(()=>{
        if(currentItem >= (images.length -1) )
            cycleItem(0)
        else
            cycleItem(currentItem + 1)
    },[images.length,currentItem])

    return(
        <div style = {{...props.style,position:'relative',display:'flex',flexDirection:'column',width:'100%'}}>
            <div style = {{...props.imgStyle,display:'flex',flexDirection:'row',overflow:'hidden',flexWrap:'nowrap', whiteSpace:'nowrap'}}>
               
                {
                    images.map((image)=>
                    <img
                    alt = 'property'
                    key = {image.photoID}
                    src = {image.image}
                    width = '100%'
                    style = {{cursor:'pointer',height:'100%',transform: `translate3d(${-currentItem * 100}%, 0, 0)`,transitionDuration:'200ms'}}
                    loading = 'lazy'
                    onClick = {()=> onClick()}
                    />)
                    
                }
   
            </div>
            <div style = {{display:'flex',width: '100%',justifyContent:'center',alignItems:'center', marginTop:'10px'}} >
                {images.map((img,index)=><Brightness1Icon key = {`${index}`} sx = {{fontSize: index === currentItem ? '12px' : '7px',color: index === currentItem ? 'text.primary' : 'text.dimGrey' }} />)}
            </div>
            <div onClick = {()=> decrementItem()} style = {{...styles.chevronBox,left:0}}>
                <ChevronLeftIcon sx = {{alignSelf:'center',justifySelf:'center',textAlign:'center'}} color = 'text.secondary' fontSize = 'large'  />
            </div>
            <div onClick = {()=> incrementItem()} style = {{...styles.chevronBox,right:0}}>
                <ChevronRightIcon sx = {{alignSelf:'center',justifySelf:'center',textAlign:'center'}} color = 'text.secondary' fontSize = 'large'  />
            </div>
        </div>
    )
}

