import React from 'react'
import {Grid} from '@mui/material'
import PropertyThumbnail from '../components/propertyThumbnail'
import EmptyPropertys from './emptyPropertys'


/**
 * Displays all featured properties. 
 * Makes API Call to fetch all featured Properties
 * @param {properties: a list of properties for display} props 
 * @returns 
 */
function FeaturedProperties(props){

    
    const featuredProperties = props.properties ? props.properties : []
   
 
  

    return(
        featuredProperties.length > 0 ?
        <Grid container spacing = {3}>
            {featuredProperties?.map(property=>
                <Grid item xs = {12} md = {4} lg = {3}> 
                    <PropertyThumbnail item = {property} /> 
                </Grid>)}
        </Grid> : <EmptyPropertys />
    )
}

export default React.memo(FeaturedProperties)