import React,{useState} from 'react'
import {TextField,Button,Container} from '@mui/material'
import {signInWithEmailAndPassword,sendPasswordResetEmail} from 'firebase/auth'
import { auth } from '../firebase'
import { useNavigate } from 'react-router-dom'
import {ADMIN} from '../routes'

export default function AdminLogin(props){

    const [email,setEmail] = useState('')
    const [password,setPAssword] = useState('')
    const navigate = useNavigate()
    async function login(){
        try{
            await signInWithEmailAndPassword(auth,email,password)
            navigate(`../${ADMIN}`)
        }catch(e){
            alert('Could not login. Ensure your email and password are correct')
        }
    }

    async function sendForgotEmail(){
        try{
            if(email.length === 0){
                alert('Type Your email in the email field before you request a password reset.')
                return
            }

            await sendPasswordResetEmail(email)
        }catch(e){
            alert('Could not send password reset')
        }
    }
    return(
        <Container disableGutters maxWidth = 'xl' sx = {{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <h2>Admin Login</h2>
            <TextField value = {email} onChange = {e=> setEmail(e.target.value)} sx = {{my:2}} label = 'Email Address' title = 'Email' type = 'email' />
            <TextField  value = {password} onChange = {e=> setPAssword(e.target.value)} sx = {{my:2}} label = 'Password' title = 'Password' type = 'password' />
            <Button onClick = {()=> login()}>Login</Button>
            <Button onClick = {()=> sendForgotEmail()}>Forgot Password</Button>
        </Container>
    )
}