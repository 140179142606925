import React,{useState,useRef,useEffect} from 'react'
import {Card,Typography,TextField,Button} from '@mui/material'
import {postPropertyInquiery} from '../backend/api'
import setStateAsync from '../backend/setStateAsync'
import LoadingComponent from './loadingComponent'
import ErrorComponent from './errorComponent'
import DoneIcon from '@mui/icons-material/Done';



export default function InquireProperty(props){
    
    const propertyID = props.propertyID
    const [loading,toggleLoading] = useState(false)
    const [error,toggleError] = useState(false)
    const [success,toggleSuccess] = useState(false)
    const mounted = useRef(true)

    const [firstName,setFirstName] = useState('')
    const [lastName,setLastName] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')

    const ERRORTEXT = `Could not send request. \n You may have already sent in an inquiery for this property using this email:${email}`

    useEffect(()=>{
        mounted.current = true

        return()=> mounted.current = false
    },[])
    async function postPropertyInquieryFunc(){
        try{
            setStateAsync(true,toggleLoading,mounted.current)
            setStateAsync(false,toggleError,mounted.current)
            const inquiery = {
                propertyID,
                user:{
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    phone_number: phone
                }
            }
            await postPropertyInquiery(inquiery)
            setStateAsync(true,toggleSuccess,mounted.current)
        }catch(e){
            setStateAsync(true,toggleError,mounted.current)
        }finally{
            setStateAsync(false,toggleLoading,mounted.current)
        }
    }

    function initializeState(){
        setEmail('')
        setFirstName('')
        setLastName('')
        setPhone('')
        toggleSuccess(false)
    }

    return(
        <Card sx = {{display:'flex',flexDirection:'column', alignItems:'center',backgroundColor:'background.lightSecondary' , py:2,px:2,mx:2,...props.style}}>
           
           {loading ? <LoadingComponent /> : error ? <ErrorComponent errorText = {ERRORTEXT} onClick = {()=> toggleError(false)} /> : success ? <>
            <DoneIcon />
            <Typography variant = 'h6'>request Received!</Typography>
            <Typography variant = 'h6'>You will receive an email soon about the next steps.</Typography>
            <Button onClick = {()=> initializeState()}>Submit another inquiery</Button>
            </> :
           <>
           <Typography variant = 'h6'>Inquire about this property</Typography>
            <TextField value = {firstName} onChange = {e=> setFirstName(e.target.value)} sx = {styles.textField} type = 'text' label = 'First Name' id = 'first_name' />
            <TextField value = {lastName} onChange = {e=> setLastName(e.target.value)} sx = {styles.textField} type = 'text' label = 'Last Name' id = 'last_name' />
            <TextField value = {email} onChange = {e=> setEmail(e.target.value)} sx = {styles.textField} type = 'email' label = 'Email Adddress' id = 'email' />
            <TextField value = {phone} onChange = {e=> setPhone(e.target.value)} sx = {styles.textField} type = 'tel' label = 'Phone' id = 'phone' />
            <Button onClick = {()=> postPropertyInquieryFunc()}>Submit</Button>
            </>
    }
        </Card>
    )
}

const styles = {
    textField:{
        my:2,
        width:'100%'
    }
}