import React,{useEffect,useState,useRef} from 'react'
import {TextField,Button,CircularProgress} from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import Card from '../Card'
import {postContactForm} from '../../backend/api'

import {EMAIL_REGEX} from '../../resources/constants'
import setStateAsync from '../../backend/setStateAsync';

export default function ContactForm(props){

    const [loading,toggleLoading] = useState(false)
    const [error,toggleError] = useState(false)
    const [success,toggleSuccess] = useState(false)
    const [email,setEmail] = useState('')
    const [first_name,setFirstName] = useState('')
    const [last_name,setLastName] = useState('')
    const [message,setMessage] = useState('')
    const mounted = useRef(true)
    
   
   
    useEffect(()=>{

        return ()=> mounted.current = false
    },[])

    async function sendMessage(){
        try{
            setStateAsync(true,toggleLoading,mounted.current)
            setStateAsync(false,toggleError,mounted.current)
            setStateAsync(false,toggleSuccess,mounted.current)
            await postContactForm(email,first_name,last_name,message)
            setStateAsync(true,toggleSuccess,mounted.current)
        }catch(e){
            setStateAsync(true,toggleError,mounted.current)
        }finally{
            setStateAsync(false,toggleLoading,mounted.current)
        }
    }

    function isActive(){
        return(
            EMAIL_REGEX.test(email) && message.length > 0
        )
    }
    function initializeData(){
        setMessage('')
        toggleSuccess(false)
        toggleError(false)
        
    }

    return(
        <Card style = {{display:'flex', flexDirection:'column', padding:15, borderRadius:2}}>
            <SendIcon  sx = {{alignSelf:'center',fontSize:'30px'}} />
            <h2 style = {{textAlign:'center'}}>Send us a Message  </h2>
            <TextField value = {first_name} onChange = {e=> setFirstName(e.target.value)} sx = {styles.textField} type = 'text' label = 'First Name' id = 'first_name' />
            <TextField value = {last_name} onChange = {e=> setLastName(e.target.value)} sx = {styles.textField} type = 'text' label = 'Last Name' id = 'last_name' />
            <TextField value = {email} onChange = {e=> setEmail(e.target.value)} helperText = 'Enter a valid Email' error = {!EMAIL_REGEX.test(email)} sx = {styles.textField} type = 'email' label = 'Email Adddress' id = 'email' />
            <TextField value = {message} onChange = {e=> setMessage(e.target.value)} helperText = 'Message must be longer than 0 characters' error = {message.trim().length === 0} sx = {styles.textField} type = 'text' label = 'Message' id = 'message' multiline rows = {4} maxRows={15} />
          { loading ? <CircularProgress /> : error ? <Button onClick = {()=> toggleError(false)}>Try again</Button> :
          success ? <Button onClick = {()=> initializeData()}>Form posted! Submit another?</Button> :
           <Button disabled = {!isActive()} onClick = {()=> sendMessage()}>Submit</Button> }
        </Card>
    )
}

const styles = {
    textField:{
        my:2,
        width:'100%'
    }
}