import React,{useState} from 'react'
import HoverableDiv from './hoverableDiv'
import DropDownButton from './dropDownButton'

export default function Disclosure(props){

    const [open,toggleOpen] = useState(false)

    return(
        <div{...props} style = {{display:'flex', flexDirection:'column'}} >
            <HoverableDiv onClick = {()=> toggleOpen(!open)} style = {{display:'flex',justifyContent:'center',cursor:'pointer',borderRadius:5}}>
                {props.title}
                <DropDownButton isOpen = {open} />
            </HoverableDiv>
        {open && props.children}
        </div>
    )
    
}