import React from "react";
import {Button} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function DropDownButton(props){
    return(
        <Button {...props} endIcon = {<ExpandMoreIcon sx = {{transform: `rotate(${props.isOpen ? '180deg' : '0deg'})`}} />} >
            {props.children}
        </Button>
    )
}