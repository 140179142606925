import React,{useState} from 'react'

export default function HoverableCard(props){

    const [hover,toggleHover] = useState(false)

    function onClick(){
        if(props.onClick)
            props.onClick()
    }

    return(
        <div onMouseEnter={()=> toggleHover(true)} onMouseLeave={()=> toggleHover(false)}  onClick = {()=> onClick()}  {...props} style = {{...styles,...props.style,backgroundColor: hover ? '#212121B3' : ''}}>
            {props.children}
        </div>
    )
}

const styles = {
    boxShadow: '0 10px 35px rgba(50,50,93,.1),0 2px 15px rgba(0,0,0,.07)',
    paddingTop:5,
    paddingBottom:5,
    borderRadius:8,
    margin:5,
    cursor:'pointer'
    

}