import React from 'react'
import { Navigate,useLocation } from 'react-router-dom'
import { useAuth } from './authContext'
import * as ROUTES from './routes'

export default function RequireAuth(props){

    const auth = useAuth()
    const location = useLocation()

    if(!auth?.user)
        return(
            <Navigate to = {`../${ROUTES.LOGIN}`} state={{ from: location }} replace />
        )

    return(
        props.children
    )
}