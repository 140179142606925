import React,{useState} from 'react'
import {Button,Menu} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../authContext';
import {signOut} from 'firebase/auth'

export default function AuthBadge(props){

    const [anchorElement,setAnchorElement] = useState(null)
    const auth = useAuth()
    const isOpen = Boolean(anchorElement)

    function handleOpen(event){
        setAnchorElement(event.currentTarget)
    }

    function handleClose(newCurrency){
        setAnchorElement(null)
    }

    async function signout(){
        try{
           await signOut(auth)
        }catch(e){
            alert('error signing out')
            console.log(e)
        }
    }
    return(
        <div style = {{...props.style}}>
        <Button onClick = {handleOpen} endIcon = {<ExpandMoreIcon sx = {{transform: `rotate(${isOpen ? '180deg' : '0deg'})`}} />} variant = 'contained' sx = {{marginRight:5,backgroundColor:'text.primaryGreen'}}>
           {auth?.user?.email}
        </Button>
        <Menu
        open = {isOpen}
        anchorEl={anchorElement}
        onClose={handleClose}
        >
           <Button onClick = {()=> signout()} >Logout</Button>
            
        </Menu>
        </div>
    )
}

