import React,{useState,useEffect,useRef} from 'react'
import {Button,ButtonGroup,Link} from '@mui/material/'
import {Link as RouterLink,useNavigate} from 'react-router-dom'
import * as ROUTES from '../routes'
import {getBannerProperties,getFeaturedProperties,getFeaturedRentals} from '../backend/api'
import setStateAsync from '../backend/setStateAsync'
import OverlayImage from '../components/overlayImage'
import FeaturedProperties from '../components/featuredProperties'
import FeaturedRentals from '../components/featuredProductsRent'
import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LoadingScreen from '../components/loadingComponent'
import ErrorScreen from '../components/errorScreen'
import Card from '../components/Card'





export default function Home(props){

    const [error,toggleError] = useState(true)
    const [loading,toggleLoading] = useState(true)
    const [listingState,setListingState] = useState(1)
    const [bannerProperties,setBannerProperties] = useState([])
    const [featuredRentals,setFeaturedRentals] = useState([])
    const [featuredProperties,setFeaturedProperties] = useState([])
    const mounted = useRef(true)
    const navigate = useNavigate()
    

    useEffect(()=>{
        setTimeout(()=>{
            getData()
        },2000)

        return ()=> mounted.current = false
    },[])

    async function getData(){
        try{
            setStateAsync(true,toggleLoading,mounted.current)
            const bannerproducts = await getBannerProperties()
            const featuredRentalData = await getFeaturedRentals()
            const featuredPropertiesData = await getFeaturedProperties()

            setStateAsync(featuredPropertiesData,setFeaturedProperties,mounted.current)
            setStateAsync(featuredRentalData,setFeaturedRentals,mounted.current)
            setStateAsync(bannerproducts,setBannerProperties,mounted.current)
            setStateAsync(false,toggleError,mounted.current)
     
        }catch(e){
            setStateAsync(true,toggleError,mounted.current)
        }finally{
            setStateAsync(false,toggleLoading,mounted.current)
        }
        
    }


    if(loading)
        return( <LoadingScreen styles = {{height:500}} /> )
    if(error)
        return(<ErrorScreen errorText = 'Error contacting server' buttonText = 'Go Home' onClick = {()=> getData()} />)

    return(
        <div style = {{pb:2,bgcolor:'paper',height:'100%'}}>
            <OverlayImage style = {{height:400}} onClick = {(propertyID)=> navigate(`/${ROUTES.PROPERTIES}/${propertyID}`)} items = {bannerProperties}  />
            <Card style = {{width:'100%',display:'flex',flexDirection:'column',backgroundColor:'background.lightSecondary',padding:2}}>
                <HomeIcon  sx = {{alignSelf:'center',justifySelf:'center',fontSize:'60px'}}/> 
                <h3  style = {{textAlign:'center',marginBottom:3}}>Featured Listings</h3>
                <ButtonGroup sx = {{alignSelf:'center', mb:5}}>
                    <Button variant = {listingState === 1 ? 'contained' : 'outlined'} onClick = {()=> setListingState(1)}>Buy</Button>
                    <Button variant = {listingState === 0 ? 'contained' : 'outlined'} onClick = {()=> setListingState(0)}>Rent</Button>
                </ButtonGroup>

                {
                    listingState === 1 ?
                    <FeaturedProperties properties = {featuredProperties} /> : 
                    <FeaturedRentals properties = {featuredRentals} />
                }
              {  ( featuredProperties?.length > 0 || featuredRentals.length > 0 ) &&  <Link component = {RouterLink} to = {ROUTES.PROPERTIES} sx = {{textAlign:'center',my:3, cursor : 'pointer'}}>See More Property for {listingState === 1 ? 'Purchase': 'Rent'}</Link> }
            </Card>
            <Card style = {{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',padding:2,}}>
                <AttachMoneyIcon  sx = {{alignSelf:'center',justifySelf:'center',fontSize:'60px'}}/> 
                <h3 style = {{textAlign:'center',margin:0,marginBottom:3}}>Get your property sold</h3>
                <p style = {{maxWidth:'400px',textAlign:'justify'}}>
                    Navigate to the sellers page,
                    Fill out a simple form and await approval to have your property listed with us.
                </p>
               
                <Link component = {RouterLink} to = {ROUTES.SELL} underline = 'none' alignSelf = 'center' ><Button>Sellers Page</Button></Link>
            </Card>
           
        </div>
    )
}