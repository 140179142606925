import React,{useState,useEffect,useRef, useCallback} from 'react'
import {Container,Button, TextField,Grid, Typography,Card,FormControlLabel,Radio,RadioGroup} from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FeaturedProperties from '../components/featuredProperties'
import {getProperties} from '../backend/api'
import LoadingComponent from '../components/loadingComponent'
import ErrorComponent from '../components/errorScreen'
import DropDownButton from '../components/dropDownButton';
import EmptyPropertys from '../components/emptyPropertys';

const sortValues = ['price','size','beds','baths']
const filterValues = [
    {
        title: 'Listing Type',
        options: [{title:'Rent',value:'rent'},{title:'Purchase',value:'buy'}],
        parameter: 'listingType'
    },
    {
        title: 'Property Type',
        options: [{title: 'Residential',value: 'residential'},{title:'Agricultural',value:'agricultural'},{title:'Commercial',value:'commercial'}],
        parameter:'propertyType'
    },
    {
        title: 'Climate Control',
        options: [{title:'Air Conditioned',value:'true'}],
        parameter: 'AC'
    },
    {
        title: 'Furnished',
        options: [{title:'Furnished',value:'true'},{title: 'Unfirnished',value: 'false'}],
        parameter: 'furnished'
    }

]




export default function MarketPage(props){

    const [loading,toggleLoading] = useState(true)
    const [error,toggleError] = useState(false)
    const [properties,setProperties] = useState([])
    const [city,setCity] = useState('')
    const firstRender = useRef(true)
    const [sortValue,setSortValue] = useState(sortValues[0])
    const [sortAsc,toggleSortMode] = useState(true)
    const [filterProps,setFilterProps] = useState({})
    const [sortOpen,toggleSortOpen] = useState(false)
    const [filterOpen,toggleFilterOpen] = useState(false)

    const handleParams = useCallback(()=>{
        const tempParams = {
            approved: true,
            enabled: true
        }
        const trimmedCity = city.trim().toLowerCase()
        
        if(trimmedCity.length > 0)
            tempParams.search = trimmedCity
        tempParams.sort = sortValue
        tempParams.sortMode = sortAsc
        tempParams.filters = filterProps
        
        return tempParams
    },[city,filterProps,sortAsc,sortValue])

    const getData = useCallback(async ()=>{
        try{
            toggleLoading(true)
            const params = handleParams()
            const data = await getProperties(params)
            setProperties(data)
            toggleError(false)
        }catch(e){
            toggleError(true)
        }finally{
            setTimeout(()=>{
                toggleLoading(false)
            },800)
            
        }
    },[handleParams])

    useEffect(()=>{
        
            getData()
    
    },[sortValue,sortAsc,filterProps,getData])

    useEffect(()=>{
        if(!firstRender.current)
            getData()
        else
            firstRender.current = false
    },[city,getData])

 

  

   function handleCityInput(value){
    setCity(value)
    
    }
    
    

    function handleFilterProps(value,parameter){
       
        const oldObj = {...filterProps}
        oldObj[parameter] = value
        setFilterProps(oldObj)
     
    }

    

    function changeSort(value){
        if(sortValue === value)
            toggleSortMode(!sortAsc)
        else{
            setSortValue(value)
            toggleSortMode(true)
        }
            
    }
    return(
        <Container maxWidth = 'xl' disableGutters = {true} container  sx = {{paddingY:5 ,paddingX:4}}>
            <Card sx = {{my:1,padding:2,justifyContent:'center',alignItems:'center',display:'flex', flexDirection:'column'}}>
                <Typography variant='h5' textAlign={'center'} sx = {{my:2}} >Customize Your Search</Typography>
                <TextField value = {city} onChange = {e=> handleCityInput(e.target.value)}  fullWidth variant = 'outlined' type = 'search' label = 'Search City'  />
                <DropDownButton sx = {{margin:2}} onClick = {()=> toggleSortOpen(!sortOpen)} isOpen = {sortOpen} > <Typography textAlign={'center'} color = 'text.primary' variant = 'h6'>Sort</Typography> </DropDownButton>
                {sortOpen ?
                <Grid maxWidth = 'xl' container spacing = {2} sx = {{width:'100%', mb:2,justifyContent:'center'}}>
                    {
                        sortValues.map(val=>{
                            return(
                                <Grid item xs = 'auto'>
                                    <Button variant= {sortValue === val ? 'contained' : 'text'} onClick = {()=> changeSort(val)} sx = {{margin:2}} >{val[0].toUpperCase() + val.substring(1)} <SortIcon refValue = {val} sortValue = {sortValue} sortMode = {sortAsc}/> </Button>
                                </Grid>
                            )
                        })
                    }
                    
                </Grid> : null
                }
                <DropDownButton sx = {{marginBottom:5}} isOpen = {filterOpen} onClick = {()=> toggleFilterOpen(!filterOpen)}><Typography color = 'text.primary' textAlign={'center'} variant = 'h6' >Filter</Typography> </DropDownButton>
                { filterOpen ?
                <Grid maxWidth = 'xl' alignItems={'center'} direction={{md:'row',xs:'column'}} container spacing = {2} rowGap={2} sx = {{width:'100%', mb:2}}>
                    { 
                        filterValues.map(val=>{
                            return (    
                                <Grid sx = {styles.filterGrid} item xs = 'auto' >
                                    <Typography textAlign='center' color = 'green' >{val.title}</Typography>
                                    <RadioGroup  onChange={(e=> handleFilterProps(e.target.value,val.parameter))} aria-label = {val.title} row = {{md:true}} defaultChecked = {false} >
                                        {
                                            val.options.map(option=> <FormControlLabel checked = {filterProps[val.parameter] === option.value} value = {option.value} label = {option.title} control = {<Radio />} />)
                                        }
                                    </RadioGroup>
                                </Grid>
                            )
                        })
                    }
                    </Grid> : null }
                    
                <div style = {{width:'100%', alignItems:'center', justifyContent:'center', display:'flex'}}>
                    <Button onClick = {()=> setFilterProps({})}>Clear Filters</Button>
                </div> 
                
            </Card>
            {loading ? <LoadingComponent style = {{maxHeight:'10vh'}}/> :
            error ? <ErrorComponent errorText = 'Could not retreive properties' buttonText = 'try again' onClick = {()=> getData()}  />
            : properties?.length > 0 ?  <FeaturedProperties properties = {properties} />  : <EmptyPropertys />
            }
        </Container>
    )
}

function SortIcon(props){
    
    const sortMode = props.sortMode
    const sortValue = props.sortValue
    const refValue = props.refValue

    if(!sortMode && sortValue === refValue)
        return(
            <Typography>
            <ArrowDownwardIcon {...props} /> (high to low) </Typography>
        )
    else if(sortValue === refValue)
        return(
           <Typography fontSize={15}> <ArrowUpwardIcon {...props}/> (low to high) </Typography> )
    else
        return null
}

const styles = {
    filterGrid: {
        backgroundColor:'background.lightSecondary',
        padding:2, 
        borderRadius:5,
        mx:1
    }
}