import React from 'react'
import * as ROUTES from '../routes'
import {Link as RouterLink} from 'react-router-dom'
import {Button,Link} from '@mui/material'

export default function EmptyPropertys(props){

    return(
        <span style = {{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',alignSelf:'center'}}>
                    <h3 style = {{textAlign:'center',margin:0}}>Our Neighborhood looks kind of empty</h3>
                    <h3 style = {{textAlign:'center',margin:0}}>Help us Full it up!</h3>
                    <Link component = {RouterLink} to = {`../${ROUTES.SELL}`} underline = 'none' alignSelf = 'center' ><Button>Submit a Property</Button></Link>
        </span>
    )
}