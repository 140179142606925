import React from 'react'
import {Typography,Container,Button,Link} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'
import logo from '../resources/logo.jpeg'

export default function NotFound(props){

    return(
        <Container sx = {{alignItems:'center', justifyContent:'center',maxWidth:'xl',display:'flex',flexDirection:'column',height:'100%'}}>
            <img alt = 'logo' src = {logo} width = '50%' />
            <Typography variant = 'body1' fontSize = {20} >Looks like you've stumbled onto the wrong neighborhood!</Typography>
            <Link underline = 'hover' to = '/' component = {RouterLink}><Button>Go Home</Button></Link>
        </Container>
    )
}