import React from 'react'
import {Typography,Container,Button} from '@mui/material'
import {useNavigate} from 'react-router-dom'

export default function ErrorScreen(props){

    const navigation = useNavigate()
    function onClick(){
        if(props.onClick)
            props.onClick()
        else
            navigation('/')
    }
    return(
        <Container disableGutters maxWidth = 'xl' sx = {{pb:2,bgcolor:'paper',height:'100vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',justifyItems:'center'}}>
            <Typography>{props.errorText}</Typography>
            <Button onClick = {()=> onClick()}>{props.buttonText}</Button>
        </Container>
    )
}