import React,{useState,useEffect,useRef,useContext} from 'react'
import {Button, TextField} from '@mui/material'
import Card from '../Card'
import {setSocials} from '../../backend/FB_API'
import setStateAsync from '../../backend/setStateAsync'
import LoadingComponent from '../loadingComponent'
import {ContactContext} from '../../contactContext'



export default function ContentManagementList(props){
    const context = useContext(ContactContext)

    const [FB,setFB] = useState(context.value.FB_LINK)
    const [IG,setIG] = useState(context.value.IG_LINK)
    const [LI,setLI] = useState(context.value.LinkedIN_LINK)
    const [email,setEmail] = useState(context.value.email)
    const [phone,setPhone] = useState(context.value.phone)
    const [address,setAddress] = useState(context.value.address)
    const [success,toggleSuccess] = useState(false)
    const [loading,toggleLoading] = useState(false)
    const mounted = useRef(true)

    

    useEffect(()=>{
    
        return ()=> mounted.current = false
    },[])
   

    async function writeData(){
        try{
            setStateAsync(true,toggleLoading,mounted.current)
                const data = {
                    
                        FB_LINK: FB,
                        IG_LINK:IG,
                        LinkedIN_LINK:LI,
                        address:address,
                        email:email,
                        phone:phone
                    
                }
                await setSocials(data)
                setStateAsync(true,toggleSuccess,mounted.current)
                context.changeValue(data)
                setTimeout(()=>{
                    setStateAsync(false,toggleSuccess,mounted.current)
                },2000)

        }catch(e){
            alert('Error Updating Data')
        }finally{
            setStateAsync(false,toggleLoading,mounted.current)
        }
    }

    if(loading)
        return(
            <LoadingComponent />
        )

    return(
        <div style = {{display:'flex', flexDirection:'column', alignItems:'center', width:'100%',paddingLeft:15, paddingRight:15}}>
            <h3 style = {{textAlign:'center'}}>Content Management</h3>
            <Card style = {{padding:10,display:'flex', flexDirection:'column',width:'100%'}}>
             { success ? 
             <div>
                 <h3 style = {{textAlign:'center'}}>Changes Posted!</h3>
             </div> :
             <>
                <TextField onChange = {e=> setFB(e.target.value)} value = {FB}  sx = {styles.textFieldStyle} label = 'Facebook Link' title='Facebook Link' />
                <TextField onChange = {e=> setIG(e.target.value)} value = {IG} sx = {styles.textFieldStyle} label = 'Instagram Link' title='Instagram Link' />
                <TextField onChange = {e=> setLI(e.target.value)} value = {LI} sx = {styles.textFieldStyle} label = 'LinkedIn Link' title='LinkedIn Link' />
                <TextField onChange = {e=> setEmail(e.target.value)} value = {email} sx = {styles.textFieldStyle} label = 'Contact Email' title='Contact Email' />
                <TextField onChange = {e=> setPhone(e.target.value)} value = {phone} sx = {styles.textFieldStyle} label = 'Contact phone' title='Contact Phone' />
                <TextField onChange = {e=> setAddress(e.target.value)} value = {address} sx = {styles.textFieldStyle} label = 'Office Location' title='Office Location' />
                <Button onClick = {()=> writeData()}>Save</Button>
            </>
             }
            </Card>
        </div>
    )
}

const styles = {
    textFieldStyle: {
        my:2,
       // mx:5
    },
}