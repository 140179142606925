import {getDoc,doc,setDoc} from 'firebase/firestore'
import {db} from '../firebase'

export async function getSocials(){
    try{
        const doc_ref = doc(db,"CMS","Contact_Info")
        const data = await getDoc(doc_ref)
        if(data.exists)
            return data.data()
        else 
            return{
                FB_LINK: "",
                IG_LINK:"",
                LinkedIN_LINK:"",
                address:"",
                email:"",
                phone:""
            }
    }catch(e){
        throw e
    }
}

export async function setSocials(socials){
    try{
        const doc_ref = doc(db,"CMS","Contact_Info")
        await setDoc(doc_ref,socials);

    }catch(e){
        throw e
    }
}