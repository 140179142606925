import {createContext} from 'react'

export const currencies = [
    {
        name: 'USD',
        value: 0.36994
    },
    {
        name: 'XCD',
        value: 1
    }
]

export const CurrencyContext =  createContext({
    value: currencies[0],
    changeCurrency: ()=> {}

})