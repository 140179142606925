import React,{useState,useContext} from 'react'
import {Button,Menu,MenuItem,Divider} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import {CurrencyContext,currencies} from '../currencyContext'
import {LandSizeUnitContext,units} from '../landSizeUnitContext'

export default function CurrencyIndicator(props){

    const [anchorElement,setAnchorElement] = useState(null)
    const currency = useContext(CurrencyContext)
    const unit = useContext(LandSizeUnitContext)
    const isOpen = Boolean(anchorElement)

    function handleOpen(event){
        setAnchorElement(event.currentTarget)
    }

    function handleClose(newCurrency,newUnit){
        if(newCurrency?.name && newCurrency?.value)
            currency.changeCurrency(newCurrency)
        if(newUnit?.name && newUnit?.value)
            unit.changeUnit(newUnit)
        setAnchorElement(null)
    }
    return(
        <div style = {{...props.style}}>
        <Button onClick = {handleOpen} startIcon = {<AttachMoneyIcon />} endIcon = {<ExpandMoreIcon sx = {{transform: `rotate(${isOpen ? '180deg' : '0deg'})`}} />} variant = 'contained' sx = {{marginRight:5,backgroundColor:'text.primaryGreen'}}>
           {currency.value.name}
           <div style = {{backgroundColor:'white', height: '100%', width:2}} />
           <ArchitectureIcon />
           {unit.value.name}
        </Button>
        <Menu
        open = {isOpen}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        PaperProps={{
        sx: { alignSelf:'center',alignContent:'center', display:'flex', flexDirection:'column'}
        }}
        >
            {
                currencies.map((value,index)=>
                    <MenuItem selected = {value.name === currency.value.name} textAlign = 'center' sx = {{alignSelf:'center'}} key = {index} onClick = {()=> handleClose(value)} ><AttachMoneyIcon />{value.name}</MenuItem>
                    )
            }
            <Divider  sx = {{width:'100%',height: 1}}/>
            {
                units.map((value,index)=> 
                <MenuItem selected = {value.name === unit.value.name} key = {index} onClick = {()=> handleClose(null,value)} ><ArchitectureIcon />{value.name}</MenuItem>
                )

            }
            
        </Menu>
        </div>
    )
}

