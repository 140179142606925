export const HOME = 'home'
export const ROOT = '/'
export const SELL = 'sell'
export const PROPERTIES = 'properties'
export const CONTACT = 'contact'
export const ADMIN = 'admin'
export const NEWPROPERTY = 'pending-propety'
export const NEWPROPERTYFORM = 'pending-propety'
export const EDITPROPETY = 'edit-property'
export const INQURIES = 'inquiries'
export const CMS = 'CMS'
export const LOGIN = 'login'
