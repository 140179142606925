import React from 'react'
import {useMediaQuery,useTheme} from '@mui/material'
import AppBarFull from './appBarFull'
import AppBarSmall from './appBarSmall'


export default function NavBar(props){
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    
    if(isMobile)
        return(<AppBarSmall />)

    return( <AppBarFull /> )
}

