import React,{useState,useEffect, useCallback,useContext} from 'react'
import { CurrencyContext } from '../currencyContext'
import {Container,Box,Typography,ButtonGroup,Button,Grid,Link,useMediaQuery,useTheme} from '@mui/material'
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {Link as RouterLink,useParams} from 'react-router-dom'
import SlideShow from '../components/slideShow'
import InquireProperty from '../components/inquireProperty'
import BedIcon from '@mui/icons-material/Bed';
import BathroomIcon from '@mui/icons-material/Bathroom';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import WeekendIcon from '@mui/icons-material/Weekend';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import LoadingComponent from '../components/loadingComponent'
import {getProperty} from '../backend/api'
import {PROPERTIES} from '../routes'
import {LandSizeUnitContext} from '../landSizeUnitContext'

export default function PropertyDetailScreen(props){

    const params = useParams()
    const propertyID = params.propertyID

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const currency = useContext(CurrencyContext).value
    const landSizeUnit = useContext(LandSizeUnitContext).value

    const [showVideo,toggleVideo] = useState(true)
    const [loading,toggleLoading] = useState(true)
    const [error,toggleError] = useState(false)
    const [property,setProperty] = useState()
    const images = property?.images
    const VIDEOID = property?.VIDEOID
    const AC = property?.AC
    const furnished = property?.furnished
    const parking = property?.parking
    const beds = property?.beds
    const baths = property?.baths
    const size = property?.size
    const price = property?.price * currency.value
    const address = property?.address
    const city = property?.city
    const description =  property?.description ? property.description : "This property sits on the luxurious Cotton Hill in the rapidly expanding village of Calibishie. Owners of this property boasts scenary unlike any other, with a breathtaking view of the atlantic. Within walking distance to the ocea, one has the option of taking a dip in the sea or the wonderful inifinity pool in the backyard. This property features a 2 car garage parking where your vehicles can hide from the elemtns. There are 3 full bedrooms and 3 full bathrooms, with one half bathroom perfect for entertaining guests. This property is fully furnished with convenient appliances like dishwashers and dryers. "
    const listingType = property?.listingType
    const propertyType = property?.propertyType
    
    const getData = useCallback(async ()=>{
        try{
            toggleLoading(true)
            const propData = await getProperty(propertyID)
            setProperty(propData)
            if(error)
                toggleError(false)
        }catch(e){
            toggleError(true)
        }finally{
            toggleLoading(false)
        }
    },[error,propertyID])
    
    useEffect(()=>{
        setTimeout(()=>{
            getData()
        },1000)
    },[getData])
    
    
    
    if(loading)
        return(<LoadingComponent/>)
    if(error)
        return(
            <Container disableGutters={true} maxWidth = 'xl' component = 'div' sx = {{display:'flex',flexDirection:'column',alignItems:'center',py:3,justifyContent:'center', height:'100vh'}} >
                <SearchOffIcon fontSize={'large'} />
                <Typography variant = 'p'>Property does not exist</Typography>
                <Link component = {RouterLink} to = {`../`} > <Typography  >Go Home</Typography> </Link>
            </Container>
        )
    
    return(
        <Container disableGutters={true} maxWidth = 'xl' component = 'div' sx = {{display:'flex',flexDirection:'column',alignItems:'center',py:3}}>
        <Grid container spacing = {2} alignItems = 'center' >

        <Grid item xs = {12} md = {7} style= {{height:'40%',marginBottom:10}}>
            { showVideo && VIDEOID ?
            <iframe width="100%" height= { isMobile ? 300 : 620} src={`https://www.youtube.com/embed/${VIDEOID}?playlist=${VIDEOID}&loop=1&autoplay=1&rel=0&modestbranding=1`} title="Real estate video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;  picture-in-picture" allowfullscreen></iframe>
            :
            <SlideShow style = {{height: isMobile ? 300 : 620 }} images = {images} />
            }
            <Box component = 'div' display = 'flex' flexDirection='column' alignItems = 'center' sx = {{backgroundColor:'background.lightSecondary',padding:1,width:'100%'}}>
                <Typography variant = 'h6' color = 'green'>{propertyType[0].toUpperCase() + propertyType.substring(1)} Property {listingType === 'rent' ? 'For Rent' : 'For Sale'}</Typography>
                <Typography variant = 'h6' color = 'green'> {price.toLocaleString('en-US',{style:'currency',currency:currency.name})} {listingType === 'rent' ? '/mo': ''} </Typography>
                <Typography variant = 'subtitle1'>{address}, {city}.</Typography>
                <ButtonGroup sx = {{alignSelf:'center',justifySelf:'center',my:5}} >
                
                {VIDEOID && <>
                <Button variant = {showVideo ? 'contained': 'outlined'} title = 'Video' onClick = {()=> toggleVideo(true) } >
                    Video Tour
                </Button>
                <Button variant = {!showVideo ? 'contained': 'outlined'} title = 'Images' onClick = {()=> toggleVideo(false) } >
                    Images
                </Button> 
                </>}
            </ButtonGroup>
            </Box>
            
            </Grid>
            <Grid item xs = {12} md = {5}>
                <InquireProperty propertyID = {propertyID}  />
            </Grid>
        </Grid>
           
            <Box component = 'div' display = 'flex' flexDirection='column' alignItems = 'center' sx = {{backgroundColor:'background.paper',padding:3,width:'100%'}}>
                <Typography variant = 'h6' textAlign = 'start' >Property Overview </Typography>
                <Box maxWidth = 'lg' display='flex' flexDirection={'column'} backgroundColor = 'background.lightSecondary' sx = {{mb:3,padding:2, borderRadius:4}}>
                    <Typography fontSize = '15px' variant = 'body' textAlign = 'justify'>{description}</Typography>
                </Box>
                <Typography variant = 'h6'>Details</Typography>
                <Grid align = 'center' maxWidth = 'lg' alignItems = 'center' container spacing = {2} sx = {{backgroundColor:'background.lightSecondary',my:2,borderRadius:2,padding:2}}>
                        <Grid align = 'center' item  flexDirection = 'row' xs = {6} md = {4}  >
                            <Typography fontSize = '15px'  variant = 'body2'>{<BedIcon fontSize = '25px' />} Bedrooms: {beds}</Typography>
                        </Grid>
                        <Grid align = 'center' direction = 'row' item xs = {6} md = {4}  >
                            <Typography fontSize = '15px' variant = 'body2'>{<BathroomIcon />} Bathrooms: {baths} </Typography>
                        </Grid>
                        <Grid align = 'center' direction = 'row' item xs = {6} md = {4}  >
                            <Typography fontSize = '15px' variant = 'body2'>{<ArchitectureIcon />} Size: {size * landSizeUnit.value} {landSizeUnit.name} </Typography>
                        </Grid>
                        <Grid align = 'center' direction = 'row' item xs = {6} md = {4}  >
                        <Typography fontSize = '15px' variant = 'body2' > <AcUnitIcon  />{AC ? 'Air Conditioned' : 'Natural Cooling'} </Typography>
                        </Grid> 
                        <Grid align = 'center' direction = 'row' item xs = {6} md = {4}  >
                        <Typography fontSize = '15px' variant = 'body2' > <LocalParkingIcon  /> Parking: {parking} vehicles </Typography>
                        </Grid> 
                        <Grid align = 'center' direction = 'row' item xs = {6} md = {4}  >
                        <Typography fontSize = '15px' variant = 'body2' > <WeekendIcon  /> {furnished? 'Fully furnished': 'Unfirnished'} </Typography>
                        </Grid> 
                        
                    </Grid>
            </Box>
            <Typography variant = 'h6' >Share</Typography>
            <Grid maxWidth = 'lg' spacing = {3} container alignSelf = 'center' justifyContent = 'center' sx = {{backgroundColor:'background.lightSecondary',my:2}}>
                <Grid item xs = 'auto'> <FacebookIcon sx = {{fontSize:40,cursor:'pointer'}} /> </Grid>
                <Grid item xs = 'auto'> <TwitterIcon sx = {{fontSize:40,cursor:'pointer'}} /> </Grid>
                <Grid item xs = 'auto'> <EmailIcon sx = {{fontSize:40,cursor:'pointer'}} /> </Grid>
            </Grid>
           <Link component = {RouterLink} to = {`../${PROPERTIES}`} > <Typography  >View more homes</Typography> </Link>
        </Container>
    )
}

