import React,{useContext} from 'react'
import {Container,Grid} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ContactForm from '../components/contactComponents/contactForm'
import Map from '../components/contactComponents/map'
import Card from '../components/Card'
import {ContactContext} from '../contactContext'


export default function ContactScreen(props){



    const socials = useContext(ContactContext).value
  

    

    return(
        <Container disableGutters maxWidth = 'xl'>
            <Grid sx = {{px:5,my:4}} container spacing={3}>
                <Grid item xs = {12} md = {8}>
                    <ContactForm />
                </Grid>
                <Grid sx = {{justifySelf:'center',alignSelf:'center'}} item xs = {12} md = {4}>
                    <Card style = {{padding:5}}>
                        <h2 style = {{textAlign:'center'}}>Contact Information</h2>
                        <p><EmailIcon />  {socials?.email}</p>
                        <p><PhoneIphoneIcon />  {socials?.phone}</p>
                        <p><PinDropIcon/>  {socials?.address}</p>
                    </Card>
                </Grid>
            </Grid>
           
            <Map />
        </Container>
    )
}