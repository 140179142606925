import React,{useState,useEffect} from 'react'
import {TextField,Typography,Button,Box,Card,Divider,RadioGroup,FormControlLabel,Radio,Step,StepLabel,Grid,CircularProgress} from '@mui/material'
import {postNewProperty} from '../backend/api'
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import ResponsiveStepper from './responsiveStepper';
import {units} from '../landSizeUnitContext'





  const propertyTypes = [{value:'commercial',label:'Commercial'},{value:'residential',label:'Residential'},{value:'agricultural',label:'Agricultural'}]
  const listingTypes = [{value:'buy',label:'Sell'},{value:'rent',label:'Rent'}]
  const emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
export default function PropertyPostForm(props){
    
    const [formPage,setFormPage] = useState(0)
    const [nextEnabled,toggleNext] = useState(false)
    const stages = ['Categorize','Contact Information','Descriptions','Review']
    
    const [success,toggleSuccess] = useState(false)
    const [loading,toggleLoading] = useState(false)
    const [error,toggleError] = useState(false)
    

    const [propertyType,setPropertyType] = useState()
    const [listingType,setListingType] = useState()

    const [firstName,setFirstName] = useState('')
    const [lastName,setLastName] = useState('')
    const [email,setEmail] = useState('')
    const [phoneNum,setPhoneNum] = useState('')

    const [address,setAddress] = useState('')
    const [city,setCity] = useState('')
    const [numBeds,setNumBeds] = useState(0)
    const [numBaths,setNumBaths] = useState(0)
    const [propertySize,setPropertySize] = useState(0)


    useEffect(()=>{
        if(formPage === 0)
            toggleNext(propertyType && listingType)
    },[propertyType,listingType,formPage])

    useEffect(()=>{
        if(formPage === 1)
            toggleNext(firstName.length > 1 && lastName.length > 1 && emailPattern.test(email))
    },[firstName,lastName,email,formPage])

    useEffect(()=>{
        if(formPage === 2)
            toggleNext(address.length > 1 && city.length > 1 && propertySize > 0)
    },[address,city,propertySize,formPage])

    
    async function submit(){
        try{

            toggleLoading(true)
            toggleError(false)
        const newProperty = {
            propertyType,listingType,address,city,
            beds:parseFloat(numBeds),
            baths:parseFloat(numBaths),
            size:parseFloat(propertySize),
            owner: {
            first_name:firstName, 
            last_name: lastName,
            email: email,
            phone_number:phoneNum,
            },
            approved:false,
            price: 0,
            parking:0
        }

        
       await postNewProperty(newProperty)

        toggleSuccess(true)
        }catch{
            toggleError(true)
        }finally{
            toggleLoading(false)
        }
        
      
    }

    function initializeState(){
        setListingType('')
        setNumBaths(0)
        setNumBeds(0)
        setPropertySize(0)
        setPropertyType('')
        setListingType('')
        setAddress('')
        setCity('')
        toggleNext(false)
        setFormPage(0)
        toggleSuccess(false)
    }

    function onClickNext(){
        if(formPage < 3)
            setFormPage(formPage + 1)
        else
        submit()
    }

    function goBack(){
        if(formPage > 0)
            setFormPage(formPage - 1)
    }

    if(loading)
    return(
        <Card sx = {{display: 'flex', flexDirection:'column',justifyContent:'center',alignItems:'center',alignSelf:'center',minHeight:'200px', padding:2,width:{xs:'100%',md:'70%'} }}  component = 'form'>
            <CircularProgress />
        </Card>
    )
    if(error)
    return(
        <Card sx = {{display: 'flex', flexDirection:'column',justifyContent:'center',alignItems:'center',alignSelf:'center',minHeight:'200px', padding:2,width:{xs:'100%',md:'70%'} }}  component = 'form'>
            <Typography>Error submitting property</Typography>
            <Button onClick={()=> toggleError(false)}>Try again</Button>
        </Card>
    )

    if(success)
        return(
            <Card sx = {{ display: 'flex', flexDirection:'column',justifyContent:'center',alignItems:'center',alignSelf:'center',minHeight:'200px', padding:2,width:{xs:'100%',md:'70%'} }}  display = 'flex' flexDirection = 'column' component = 'form'>
                <DoneIcon />
                <Typography>Form posted!</Typography>
                <Typography>You will receive an email for next steps.</Typography>
                <Button onClick = {()=> initializeState()}>Submit another property</Button>
            </Card>
            )
        


    return(
        <Card sx = {{alignItems:'center',minHeight:'100px' ,alignSelf:'center', padding:2,position:'relative',width:{xs:'100%',md:'70%'}}} display = 'flex' flexDirection = 'column' component = 'form'>
            <ResponsiveStepper variant = 'text' position = 'static' steps = {stages.length} alternativeLabel activeStep = {formPage} sx = {{alignSelf:'center',my:5}} >
                {
                    stages.map((stage,index)=>
                        <Step key = {index +1 }>
                            <StepLabel>{stage}</StepLabel>
                        </Step>
                        )
                }
            </ResponsiveStepper>

            {
                formPage === 0 ?
                <PageOne propertyType = {propertyType} listingType = {listingType} onPropertyChange = {setPropertyType} onListingChange = {setListingType} /> :
                formPage === 1 ?
                <PageTwo firstName = {firstName} lastName = {lastName} email = {email} phoneNum = {phoneNum} setFirstName = {val=> setFirstName(val)}
                    setLastName = {setLastName} setEmail = {setEmail} setPhoneNum = {setPhoneNum}
                /> :
                formPage === 2 ?
                <PageThree propertyType = {propertyType} address = {address} city = {city} numBeds = {numBeds} numBaths = {numBaths} propertySize = {propertySize}
                setAddress = {setAddress} setCity = {setCity} setNumBeds = {setNumBeds} setNumBaths = {setNumBaths} setPropertySize = {setPropertySize}
                /> : 
                <ReviewPage firstName = {firstName} lastName = {lastName} email = {email} listingType = {listingType} propertyType = {propertyType} propertySize = {propertySize}
                    address = {address} city = {city} numBeds = {numBeds} numBaths = {numBaths} phoneNum = {phoneNum}
                /> 
            }
            <Divider sx = {{width:'100%'}} />
            <Box display = 'flex' flexDirection = 'row' justifyContent = 'space-evenly'>
            <Button onClick = {()=> goBack()} disabled = {formPage <= 0} >Back</Button>
            <Button disabled = {!nextEnabled} onClick = {()=> onClickNext()}>{ formPage < 3 ? 'Next':  'Submit'}</Button>
            </Box>
        </Card>
    )
}

function PageOne(props){

    const propertyType = props.propertyType
    const onPropertyChange = props.onPropertyChange

    const listingType = props.listingType
    const onListingChange = props.onListingChange

    return(
        <Box display = 'flex' flexDirection = 'column' alignItems = 'center' px = {5} >
            <Divider sx = {{width:'100%'}}>Property Type</Divider>
            <RadioGroup  row = 'md'  sx = {{my:2 }} onChange = {e=> onPropertyChange(e.target.value)} value = {propertyType}>
                {
                    propertyTypes.map((type,index)=>
                        <FormControlLabel key = {index} value = {type.value} label = {type.label} control = {<Radio/>} />
                        )
                }
           
            </RadioGroup>
            <Divider sx = {{width:'100%'}}>Listing Type</Divider>
            <RadioGroup row = {true} sx = {{my:2}} onChange = {e=> onListingChange(e.target.value)} value = {listingType}>
                {
                    listingTypes.map((type,index)=>
                        <FormControlLabel key = {index} value = {type.value} label = {type.label} control = {<Radio/>} />
                        )
                }
            </RadioGroup>
        </Box>
    )
}

function PageTwo(props){

    const firstName = props.firstName
    const lastName = props.lastName
    const email = props.email
    const phoneNum = props.phoneNum

    const setFirstName = props.setFirstName
    const setLastName = props.setLastName
    const setEmail = props.setEmail
    const setPhoneNum = props.setPhoneNum

    return(
        <Box display = 'flex' flexDirection = 'column' alignItems = 'center' px = {5}  >
            <Divider sx = {{width:'100%'}}>Contact Information</Divider>
            <Box display = 'flex' flexDirection = 'row' justifyContent = 'space-evenly' width = '100%'>
                <TextField required value = {firstName} onChange = {e=>setFirstName(e.target.value)} sx = {{...styles.textStyle, mr:1}} id = 'first-name' type = 'text' label = 'First Name'  />
                <TextField required value = {lastName} onChange = {e=> setLastName(e.target.value)} sx = {{...styles.textStyle, ml:1}} id = 'last-name' type = 'text' label = 'Last Name'  />
            </Box>
            <TextField error = {!emailPattern.test(email) && email.length > 0} value = {email} onChange = {e=> setEmail(e.target.value)} sx = {styles.textStyle} required id = 'email' type = 'email' label = 'Email Address' helperText = 'Valid Email Address' />
            <TextField value = {phoneNum} onChange = {e=>setPhoneNum(e.target.value)} sx = {styles.textStyle} id = 'phone_number' type = 'tel' label = 'Phone Number' />
        </Box>
    )
}

function PageThree(props){
    const propertySize = props.propertySize
    const propertyType = props.propertyType
    const address = props.address
    const city = props.city
    const numBeds = props.numBeds
    const numBaths = props.numBaths
    
    const setAddress = props.setAddress
    const setCity = props.setCity
    const setPropertySize = props.setPropertySize
  //  const setNumBeds = props.setNumBeds
   // const setNumBaths = props.setNumBaths

    return(
        <Box display = 'flex' flexDirection = 'column' alignItems = 'center' px = {5}  >
            <Divider sx = {{width:'100%'}}>Property Details</Divider>
            <Divider>Location</Divider>
            <TextField value = {address} onChange = {e=> setAddress(e.target.value)} sx = {styles.textStyle} id = 'address' type = 'text' label = 'Address'/>
            <TextField id = 'city' type = 'text' value = {city} onChange = {e=> setCity(e.target.value)} sx = {styles.textStyle} label = 'City'
            />
            <Divider>Details</Divider>
            { propertyType === propertyTypes[1]?.value ?
            <Box display = 'flex' flexDirection = 'row'>
                <TextField value = {numBeds} onChange = {e=>props.setNumBeds(e.target.value)} sx = {{...styles.textStyle, mr:1}} id = 'number_beds' required type = 'number' label = 'Number of Bedrooms' />
                <TextField value = {numBaths} onChange = {e=> props.setNumBaths(e.target.value)} sx = {{...styles.textStyle, ml:1}} id = 'number_baths' required type = 'number' label = 'Number of Bathrooms' />
            </Box> : null
            }
            <TextField value = {propertySize} onChange = {e=>setPropertySize(e.target.value)} sx = {styles.textStyle} id = 'prop_size' required type = 'number' label = {`Property Size in Acres (${units[1].value * propertySize} Sq ft) `} />
    </Box>
    )
}



function ReviewPage(props){
    const firstName = props.firstName
    const lastName = props.lastName
    const email = props.email
    const phoneNum = props.phoneNum
    
    const propertyType = props.propertyType
    const listingType = props.listingType

    const address = props.address
    const city = props.city
    const numBeds = props.numBeds
    const numBaths = props.numBaths
    const propertySize = props.propertySize
    

    return(
        <Box display = 'flex' flexDirection = 'column' alignItems = 'center' px = {5} >
            <Divider sx = {{width: '100%'}}>Contact Information</Divider>
            <Box>
                <Typography>First Name: {firstName}</Typography>
                <Typography>Last Name:  {lastName}</Typography>
            </Box>
            <Typography>Email: {email}</Typography>
            <Typography>Phone Number: {phoneNum}</Typography>
            <Divider sx = {{width: '100%'}}>Property Details</Divider>
            <Box>
                <Typography>Property Type: {propertyType}</Typography>
                <Typography>Listing Type:  {listingType}</Typography>
            </Box>
            {propertyType === 'residential' &&
            <>
            <Typography>Number of Bedrooms: {numBeds}</Typography>
            <Typography>Number of Baths: {numBaths} </Typography> </> }
            <Typography>Property Size: {propertySize} acres </Typography>
            <Divider sx = {{width: '100%'}}>Location</Divider>
            <Typography>Address: {address} </Typography>
            <Typography>City: {city} </Typography>

        </Box>
    )
}

export function HoverAbleImg(props){
    const img = props.image
    const key = props.key
    const removeImage = props.removeImage
    const [hover,toggleHover] = useState(false)
    console.log(img)
    return(
        <Grid onMouseEnter = {()=>toggleHover(true)} onMouseLeave = {()=>toggleHover(false)}  sx = {{position:'relative',padding:0}} item xs = {6} md = {4} lg = {2}>
                    <img height = '100px' width = '100%' key = {key} src = {img.image} alt = 'submission'  />
                    <div  onClick = {()=> removeImage(img)} style = {{position:'absolute', bottom:0,left:0,zIndex:100,width:'100%',height:'100%',
                                     backgroundColor:'#212121B3',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',
                                     opacity: hover ? 1 : 0
                                     }}>
                        <ClearIcon />
                    </div>
        </Grid>
    )
}

const styles = {
    textStyle : {
        my:1,
        width:1
    },
    boxStyle : {
        alignItems:'center'
    }
}