import * as React from 'react';
import {Link} from '@mui/material'
import {Link as RouterLink,useLocation} from 'react-router-dom'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import * as ROUTES from '../routes'
import MailingListForm from './mailingListForm';
import {ContactContext} from '../contactContext'

function Copyright() {
  return (
    <p style = {{color:"text.secondary", margin:0}}variant="body2" >
      {'Copyright © '}
      <Link color="inherit" href="https://choicerealestateda.com">
        Choice Real Estate
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </p>
  );
}

export default function StickyFooter() {

  const [width,setWidth] = React.useState(window.innerWidth)
  const socials = React.useContext(ContactContext).value

  React.useEffect(()=>{
    window.addEventListener("resize",()=>setWidth(window.innerWidth))
  },[])

  const location = useLocation()
  
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '0vh',
      }}
    >
    
      <div
        component="footer"
        style={{
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 2,
          paddingBottom:10,
          marginTop: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >{ !location.pathname.includes(ROUTES.ADMIN) &&
          <div  style = {{marginBottom:2,paddingLeft:15, paddingRight:15,display:'flex',flexWrap:'wrap',justifyContent:'space-evenly' }}>
                <span style = {{alignItems:'center',flex:1}} >
                    <p style = {styles.titleStyle} >Follow our Socials</p>
                    <Link color="inherit" href={socials.FB_LINK}><p style = {{cursor:'pointer',my:1}} ><FacebookIcon/> Facebook</p> </Link>
                    <Link color="inherit" href={socials.IG_LINK}><p style = {{cursor:'pointer',my:1}} ><InstagramIcon/> Instagram</p></Link>
                    <Link color="inherit" href={socials.LinkedIN_LINK}><p style = {{cursor:'pointer',my:1}} ><LinkedInIcon/> LinkedIn</p></Link>
                </span>
               
                <span style = {{alignItems:'center',flex:1}} >
                    <p style = {styles.titleStyle} >Learn More about us</p>
                    <Link to = 'about' component = {RouterLink}><p>About</p></Link>
                    <Link to = 'contact' component = {RouterLink}><p>Contact</p></Link>
                </span>
                <MailingListForm titleStyle = {styles.titleStyle} textStyle = {{width: width < 750 ?'100%' : '70%'}} style = {{flex:1, marginTop: 2,marginBottom:2}} />
                
            </div>
      } 
        <div style = {{display:'flex',justifyContent:'center'}} maxWidth="sm">
          <Copyright />
        </div>
      </div>
    </div>
  );
}

const styles = {
    titleStyle: {
        mb:1
    }
}