import React,{useState,useEffect, useCallback,useContext} from 'react'
import { CurrencyContext } from '../currencyContext';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HoverableDiv from './hoverableDiv';
import image from '../resources/logo.jpeg'

/**
 * Function creates a slideshow of images with descriptions.
 * @param {items: a list of Items which will be passed onto slideshow} props 
 * @returns 
 */
 function OverlayImage(props){
    const items = props.items 
    const [currentItem,cycleItem] = useState(0)
    const [width,setWidth] = useState(window.innerWidth)
    const currency = useContext(CurrencyContext).value

    const navigate = useNavigate()
    const location = items[currentItem]?.address + ', ' + items[currentItem]?.city
    const price = items[currentItem]?.price ? items[currentItem]?.price * currency.value : 0
    const beds = items[currentItem]?.beds
    const baths = items[currentItem]?.baths
    const sold = items[currentItem]?.sold
    const soldPrice = items[currentItem]?.soldPrice ? items[currentItem]?.soldPrice * currency.value : 0
    const propertyID = items[currentItem]?.propertyID

    function handleResize(e){
        setWidth(window.innerWidth)
    }

    useEffect(()=>{
        window.addEventListener('resize',handleResize)
    },[])

    //memoized hook used to decrement index of current slideshow item
    const decrementItem = useCallback(()=>{
        if(currentItem <= 0)
            cycleItem(items.length -1)
        else
            cycleItem(currentItem - 1)
    },[items.length,currentItem])

    //memoized hook used to incrememnt index of current slideshow item
    const incrementItem = useCallback(()=>{
        if(currentItem >= (items?.length -1) )
            cycleItem(0)
        else
            cycleItem(currentItem + 1)
    },[items.length,currentItem])


    //useEffect hook creates an interval to automatically incrememnt slideshow index.
    useEffect(()=>{
        const loop = setInterval(incrementItem,5000)
        return ()=> clearInterval(loop)
    },[currentItem,incrementItem])
    

    //follows props.onClick.
    function onClick(){
        if(props.onClick)
            props.onClick(propertyID)
    }

  

const [isHover,toggleHover] = useState(false)
const styles = {
    chevronBox: {paddingLeft:2, paddingRight:2,
                height: '100%', 
                position: 'absolute',top:0,
                display:'flex',flexDirection:'column',
                alignItems: 'center',justifyContent:'center', 
                cursor:'pointer'
    }
    
}

    return(
        
            <div style= {{...props.style,cursor:'pointer',position:'relative',maxHeight: width < 720 ? '200px' : '400px'}} onMouseLeave = {()=> toggleHover(false)} onMouseEnter = {()=> toggleHover(true)} >
                <div  style = {{display:'flex',overflow:'hidden',width:'100%',flexWrap:'nowrap',margin:0,whiteSpace:'nowrap',alignContent:'center',maxHeight:'100%'}}>
                 {items.length > 0 ? items?.map((item,index)=>
                <BannerImg 
                index = {index}
                image = {item?.images[0].image}
                onClick = {()=> onClick()}
                width = {width}
                style = {{transform: `translate3d(${-currentItem * 100}%, 0, 0)`,transitionDuration:'200ms',alignSelf:'center',objectFit:'contain',height:'100%',width:'100%'}}
                />
                ) : 
                <BannerImg 
                index = {0}
                image = {image}
                width = {width}
                onClick = {()=> navigate('/')}
                style = {{transform: `translate3d(${-currentItem * 100}%, 0, 0)`,transitionDuration:'200ms',alignSelf:'center',objectFit:'contain',height:'100%',width:'100%'}}
                /> }
                </div>
                
            <span onClick = {()=> onClick()} style= {{paddingTop:5, paddingBottom:5,position:'absolute',bottom:0,backgroundColor:'#212121B3',width:'100%', opacity: !isHover ? 0 : 1}}>
                <p style={{textAlign:'center',color: '#E0E0E0',margin:0}}>{items.length > 0 && location} </p>
                <p style = {{textAlign:'center',color: '#E0E0E0',margin:0}}>{items.length > 0 &&`${beds} bed, ${baths} bath`} </p>
                <p style = {{textAlign:'center',color: sold ? '#E0E0E0' : 'lightgreen',margin:0,textDecoration: sold ? 'line-through' : null}}>{items.length > 0 && price?.toLocaleString('en-US',{style:'currency',currency:currency.name})} </p>
                { sold && <p style = {{textAlign:'center',color: 'lightgreen',margin:0}}>SOLD: {soldPrice?.toLocaleString('en-US',{style:'currency',currency:currency.name})} </p> }
            </span>
            
            <HoverableDiv onClick = {()=> decrementItem()} style = {{...styles.chevronBox,left:0}}>
                <ChevronLeftIcon sx = {{alignSelf:'center',justifySelf:'center',textAlign:'center'}} color = 'text.primaryLight' fontSize = 'large'  />
            </HoverableDiv>
            <HoverableDiv onClick = {()=> incrementItem()} style = {{...styles.chevronBox,right:0}}>
                <ChevronRightIcon sx = {{alignSelf:'center',justifySelf:'center',textAlign:'center'}} color = 'text.primaryLight' fontSize = 'large'  />
            </HoverableDiv>
            
            </div>
        
        
    )
}

function BannerImg(props){
    return(
        <img
        key = {props.index}
        src = {props.image}
        alt = 'cover properties'
        width = '100%'
        height = {props.width < 720 ? '200px' : '400px'}
        onClick = {()=> props.onClick()}
        style = {props.style}
        />
    )
}

export default React.memo(OverlayImage)

