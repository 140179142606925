import React,{useState,useEffect,useRef} from 'react'
import {TextField,Button,CircularProgress} from '@mui/material'
import setStateAsync from '../backend/setStateAsync'
import {addToMailingList} from '../backend/api'
import {EMAIL_REGEX} from '../resources/constants'

export default function MailingListForm(props){

    const [email,setEmail] = useState('')
    const [loading,toggleLoading] = useState(false)
    const [error,toggleError] = useState(false)
    const [success,toggleSuccess] = useState(false)

    const mounted = useRef(true)

    useEffect(()=>{

        return ()=> mounted.current = false
    },[])


    async function addToEmail(){
        try{
            setStateAsync(true,toggleLoading,mounted.current)
            setStateAsync(false,toggleError,mounted.current)
            await addToMailingList(email)
            setStateAsync(true,toggleSuccess,mounted.current)
            setTimeout(()=>{
                setStateAsync(false,toggleSuccess,mounted.current)
            },2000)
        }catch(e){
            console.log(e)
            setStateAsync(true,toggleError,mounted.current)
            setTimeout(()=>{
                setStateAsync(false,toggleError,mounted.current)
            },2500)
        }finally{
            setStateAsync(false,toggleLoading,mounted.current)
        }
    }
    
    return(
        <span style = {{display:'flex',flexDirection:'column',alignItems:'center',...props.style}} >
            <p sx = {{...props.titleStyle}} >Join  our Email List</p>
            <TextField onChange = {e=> setEmail(e.target.value)}  variant = 'filled' label = 'Email' autoFocus = {false}  id = 'email' sx = {{...props.textStyle}}/>
           {loading ?  <CircularProgress /> :
           <Button disabled = {!EMAIL_REGEX.test(email)} onClick = {()=> addToEmail()} sx = {{alignSelf:'center'}} >{ success ? 'Thanks for Joining!' : error ? 'Error joining' : 'Join'}</Button> }
        </span>
    )
}