import axios from 'axios'




const apiurl = 'https://choice-real-estate-backend.herokuapp.com/v1/'
const PROPERTIES = 'properties'
const PROPERTY_IMAGES = 'property_images'
const PROPERTY_INQUIRIES = 'property_inquieries'
const ADD_TO_MAILING_LIST = 'addToMailingList'
const SEND_CONTACT_FORM = 'sendMessage'

const instance = axios.create({
    baseURL: apiurl
})

export async function addToMailingList(email){
    try{
        const data = {email}
        const res = await instance.post(`${ADD_TO_MAILING_LIST}/`,data)
        return res.status
    }catch(e){
        throw e
    }
}

export async function postContactForm(email,first_name,last_name,message){
    try{
        const data = {email,first_name,last_name,message}
        const res = await instance.post(`${SEND_CONTACT_FORM}/`,data)
        return res.status
    }catch(e){
        throw e
    }
}
/**
 * Gets full property details for a specific property
 * @param {ID of specific property} propertyID 
 */
export async function getProperty(propertyID){
    try{
        
        const response = await instance.get(`${PROPERTIES}/${propertyID}/`)
        parseProperty(response.data)
        return response.data

    }catch(e){
        throw(e)
    }
}

export async function getProperties(params){
    try{
        const response = await instance.get(`${PROPERTIES}/`,{params})
        parseProperty(response.data)
        return response.data
        
        
    }catch(e){
        console.log(e)
    }
}

/**
 * Gets properties which have not been approved for use yet
 * @returns list of properties
 */
export async function getPendingProperties(){
    try{
        const params = {
            approved:false
        }
        const data = await instance.get(`${PROPERTIES}/`,{params})
        parseProperty(data.data)
        return data.data
    }catch(e){

    }
}

/**
 * Gets the list of banner properties.
 * @returns array of banner properties: [propertyID,image,address,city,price,status]
 */
export async function getBannerProperties(){
  
    try{
        const params = {
            banner:true,
            approved:true,
            enabled:true
        }
        const result = await instance.get(`${PROPERTIES}/`,{params})
        parseProperty(result.data)
        return result.data
    }catch(e){
        throw e
    }
         
}

/**
 * Fetches list of featured properties
 * @returns array of featured properties: [propertyID,images,beds,baths,parking,size,address,city,price]
 */
export async function getFeaturedProperties(){
    try{
        const params = {
            featured:true,
            listingType:'buy',
            approved:true,
            enabled:true
        }
        const result =  await  instance.get(`${PROPERTIES}/`,{params})
        parseProperty(result.data)
        return result.data
    }catch(e){
        throw e
    }

  
}

/**
 * Fetches list of featured properties for rent
 * @returns array of featured properties for rent: [propertyID,images,beds,baths,parking,size,address,city,price]
 */
export async function getFeaturedRentals(){
    
    try{
        const params = {
            featured: true,
            listingType:'rent',
            approved:true,
            enabled:true
        }
        const data =  await instance.get(`${PROPERTIES}/`,{params })

         parseProperty(data.data)
         return data.data
    }catch(e){
        throw e
    }
  
}

export async function putProperty(property,propertyID){
    try{
        
        const data = property
        parseProperty(data)
        const response = await instance.patch(`${PROPERTIES}/${propertyID}/`,data)
        return response.data
    }catch(e){
        console.log(e)
        throw e
    }
    

    

}

export async function postNewProperty(property){
    try{
        const data = property
       
        const request = await instance.post(`${PROPERTIES}/`,data)
        return request.data
     
    }catch(e){
        throw(e)
    }
}

export async function postPropertyImage(propertyID,image){
    try{

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        if(Array.isArray(image))
            return await Promise.all( image.map( async newImg=> { 
                const data = new FormData()
                data.append('propertyID', propertyID)
                data.append('image',newImg?.file, newImg?.file?.name)
                
                const request = await instance.post(`${PROPERTY_IMAGES}/`,data,config)
                return request.data
                
            }) )
        else
            {
                const data = new FormData()
                data.append('propertyID', propertyID)
                data.append('image',image?.file,image?.file?.name)

                const request = await instance.post(`${PROPERTY_IMAGES}/`,data,config)
                return request.data
            }
        
            

        
    }catch(e){
        throw e
    }
}

export async function deletePropertyImage(photos){
    try{
                if(Array.isArray(photos)){

                    const request = await Promise.all(photos.map(async photo=> {
                        const res = await instance.delete(`${PROPERTY_IMAGES}/${photo.photoID}/`)
                        return res.data
                    }))

                    return request

                }else{
                    const request = await instance.delete(`${PROPERTY_IMAGES}/${photos.photoID}/`)
                    return request.data
                }
                                
                
        
    }catch(e){
        throw e
    }
}

export async function postPropertyInquiery(inquiery){
    try{

        const result = await instance.post(`${PROPERTY_INQUIRIES}/`,inquiery)
        return result.data

    }catch(e){
        throw e
    }
}

export async function patchPropertyInquiery(inquiery,inquieryID){
    try{
        const result = await instance.patch(`${PROPERTY_INQUIRIES}/${inquieryID}/`,inquiery)
        return result.data
    }catch(e){
        throw e
    }
}

export async function getPropertyInquiries(){
    try{
        const params = {
            exclude_status:'resolved',
        }
        const result = await instance.get(`${PROPERTY_INQUIRIES}/`,{params})
        return result.data
    }catch(e){
        throw e
    }
}

export async function deleteProperty(propertyID){
    try{
       const result = await instance.delete( `${PROPERTIES}/${propertyID}/`)
       return result.data
    }catch(e){
        throw(e)
    }
}

function parseProperty(property){

    if (typeof(property.price) === 'string')
        property.price = parseFloat(property.price)
    if(typeof(property.soldPrice) === 'string')
        property.soldPrice = parseFloat(property.soldPrice)
    if(typeof(property.size) === 'string')
        property.size = parseFloat(property.size)
    if(typeof(property?.beds) === 'string')
        property.beds = parseInt(property.beds)
    if(typeof(property?.baths) === 'string')
        property.baths = parseInt(property.baths)
    if(typeof(property?.parking) === 'string')
        property.parking = parseInt(property.parking)

}


  
  