import React from 'react'
import {CircularProgress} from '@mui/material'

export default function LoadingComponent(props){

    return(
        <div style = {{padding:2,backgroundColor:'paper',display:'flex',height:'100vh',flexDirection:'column',justifyContent:'center',alignItems:'center',justifyItems:'center',...props.styles}}>
            <CircularProgress sx = {{justifySelf:'center'}} color = 'success' />
        </div>
    )
}