import React from 'react'
import {MapContainer,TileLayer,Marker} from 'react-leaflet'

export default function Map(props){

    const lat = 15.300645
    const lng = -61.387449
    
    return(
        <MapContainer style = {{height:400}} center = {[lat,lng]} zoom = {30} scrollWheelZoom = {false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            
            />
            <Marker position = {[lat,lng]}>

            </Marker>
        </MapContainer>
    )
}