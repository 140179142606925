import React,{useState} from 'react'
import {Grid} from '@mui/material'
import Divider from './Divider'
import HoverAbleImg from './hoverableImage'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function AddImageBlock(props){
    const [hover,toggleHover] = useState(false)

    const images = props.images
    const addImage = props.addImage
    const removeImage = props.removeImage
    
    return(
        <div style = {{display:'flex',flexDirection:'column', alignItems:'center',paddingLeft:10,paddingRight:10}}>
            <Divider style = {{width:'100%',marginTop:5,marginBottom:5}}>Images</Divider>
            <label  onMouseEnter = {()=> toggleHover(true)} onMouseLeave = {()=> toggleHover(false)} htmlFor = 'upload-button' 
            style = {{cursor:'pointer',minHeight:'250px',minWidth:'70%', border : '2px dashed', borderColor:'grey',backgroundColor: hover ? '#bbdefb' :'transparent',
            padding:'15px', marginBottom:'20px',display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center' }} >
                <input onChange = {e=>{
                    const newImages = []
                    const maxLength = 7
                    if(newImages.length + images.length + e.target.files.length > maxLength){
                        alert(`Please upload less than ${maxLength} images`)
                    
                    }
                    for(let i = 0; i < ( (e.target.files.length + images.length >= 7) ? (images.length >= 7) ? 0: 7-images.length : e.target.files.length); i ++){
                        const imgFile = e.target.files[i]
                        newImages.push({image: URL.createObjectURL(imgFile),file: imgFile })
                    }
                    
                    addImage(newImages)
                    } } id = 'upload-button' accept="image/*" type = 'file' multiple/>
                <CloudUploadIcon align sx = {{textAlign:'center', fontSize:'30px'}} />
                <h2 textAlign = 'center' >Upload Images</h2>
                    
            </label>
            <Divider style = {{width:'100%',marginTop:5,marginBottom:5}}>Images</Divider>
            <Grid container width = '100%' spacing = {2} my = {2} >
            {
                images?.map((img,key)=>
                <HoverAbleImg removeImage = {removeImage} key = {key} image = {img} />
                    )
            }
            </Grid>
        </div>
    )
}