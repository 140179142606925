import React from 'react'
import {Button} from '@mui/material'

/**
 * errorText,onClick,style
 * @param {} props 
 * @returns 
 */
export default function ErrorComponent(props){

    const errorText = props.errorText
    function onClick(){
        if(props.onClick)
            props.onClick()
    }
    return(
        <div style = {{display:'flex',alignItems:'center',flexDirection:'column',width:'100%', ...props.style}}>
            <h3 style = {{textAlign:'cetner'}}>{errorText}</h3>
            <Button onClick = {()=> onClick()}>Try again</Button>
        </div>
    )
}