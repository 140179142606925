import React from 'react'

export default function OverLayComponent(props){

    const hover = props.hover

    return(
    <span {...props} style = {{position:'absolute', bottom:0,left:0,zIndex:1,width:'100%',height:'100%',
    backgroundColor:'#0E2A404A',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',
    opacity: hover ? 1 : 0,
    ...props.style}} />

    )

}