import React from 'react'

export default function Card(props){

    return(
        <div  {...props} style = {{...styles,...props.style}}>
            {props.children}
        </div>
    )
}

const styles = {
    boxShadow: '0 10px 35px rgba(50,50,93,.1),0 2px 15px rgba(0,0,0,.07)',
    paddingTop:5,
    paddingBottom:5,
    borderRadius:8,
    margin:5,
    

}