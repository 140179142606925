import {createTheme} from '@mui/material/styles'

const theme = createTheme({
    palette:{
        background:{
            paper: '#fff',
            transulscentDark: '#212121B3',
            lightSecondary: '#fafafa'
        },
        text:{
            primary: '#173A5E',
            primaryLight: '#F5F5F5',
            secondary: '#46505A',
            primaryGreen: '#006400',
            dimGrey: '#696969'

        }
    }
})

export default theme