import React,{useState,useEffect,useRef} from 'react'
import {Button,Select,MenuItem,CircularProgress} from '@mui/material'
import Card from '../Card'
import { useNavigate } from 'react-router-dom'
import * as ROUTES from '../../routes'
import Divider from '../Divider'
import Disclosure from '../discolsure'
import setStateAsync from '../../backend/setStateAsync'
import HoverableCard from '../hoverableCard'
import {patchPropertyInquiery} from '../../backend/api'


export default function PropertyInquieryThumbnail(props){
    const setInquieries = props.setInquiries
    const inquiery = props.inquiery
    const user = inquiery?.user_info
    const property = inquiery?.property_info
    const [status,setStatus] = useState(inquiery?.status)
    const [isChange,setIsChange] = useState(false)
    const [loading,toggleLoading] = useState(false)
    const [success,toggleSuccess] = useState(false)
    const [error,toggleError] = useState(false)
    const navigate = useNavigate()

    const mounted = useRef(true)

    useEffect(()=>{
        mounted.current = true
        return ()=> mounted.current = false
    },[])
    function handleStatusChange(value){
        setStatus(value)
        setIsChange(true)
    }

    async function saveChanges(){
        try{
            setStateAsync(true,toggleLoading,mounted.current)
            setStateAsync(false,toggleError,mounted.current)
            setStateAsync(false,toggleSuccess,mounted.current)
            const newProperty = JSON.parse(JSON.stringify(inquiery))
            delete newProperty.inquieryID
            newProperty.status = status
            const newInquiery = await patchPropertyInquiery(newProperty,inquiery.inquieryID)
            setInquieries(newInquiery)
            setStateAsync(true,toggleSuccess,mounted.current)
            setTimeout(()=>
            {
                setStateAsync(false,toggleSuccess,mounted.current)
            },2500)
        }catch(e){
            setStateAsync(true,toggleError,mounted.current)
        }finally{
            setStateAsync(false,toggleLoading,mounted.current)
        }
    }

    return(
        <Card>
            <h2>Inquiery: # {inquiery?.inquieryID}</h2>
            <Divider style = {{width: '100%'}} />
            <h2>Customer Information</h2>
            <h3 style = {titleStyle}>Name: {user?.first_name + ' ' + user?.last_name}</h3>
            <h3 style = {titleStyle}>Email: {user?.email}</h3>
            <h3 style = {titleStyle}>Phone: {user?.phone_number}</h3>
            <Divider />
            <h3 >Date received: {new Date(inquiery.date_posted).toLocaleDateString()}</h3>
            <h3 style = {titleStyle}>Inquiery Status</h3>
            <Select sx = {{color: status === 'pending' ? 'red' : ''}} value = {inquiery.status} onChange={e=> handleStatusChange(e.target.value)} label = 'Inquiery Status'>
                
                <MenuItem value = 'pending' >Pending</MenuItem>
                <MenuItem value = 'scheduled'>Scheduled</MenuItem>
                <MenuItem value = 'resolved'>Resolved</MenuItem>
            </Select>
           { isChange ? loading ? <CircularProgress /> : success ? <p>Inquiery Updated!</p> : error ? 
           <Button onClick = {()=> toggleError(false)}> Error. Try again</Button> : 
           <Button onClick = {()=> saveChanges()} >Update Status</Button> : null }
            <Divider style = {{width:'100%',marginTop:10,marginBottom:10}} />
            <Disclosure>
            
            <HoverableCard onClick = {()=> navigate(`../${ROUTES.PROPERTIES}/${property?.propertyID}`,{target:'_blank'})} style = {{width:'70%'}}>
            <h2>Property Information</h2>
            <h3 style = {titleStyle}>Address: {property?.address}</h3>
            <h3 style = {titleStyle}>City: {property?.city}</h3>
            <h3 style = {titleStyle}>Asking Price: {property?.price}</h3>
            <Button onClick = {()=> navigate(`../${ROUTES.PROPERTIES}/${property?.propertyID}`,{target:'_blank'})}>View Property</Button>
            </HoverableCard>
            <Divider style = {{width:'100%'}} />
            {props.children}
            </Disclosure>
        </Card>
    )
}

const titleStyle = {
    margin:2
}