import React,{useState} from 'react'
import {AppBar,Grid,Link,List,ListItem,Drawer, ListItemText,IconButton} from '@mui/material'
import * as ROUTES from '../routes'
import {Link as RouterLink,useLocation} from 'react-router-dom'
import logo from '../resources/logo.jpeg'
import MenuIcon from '@mui/icons-material/Menu';
import CurrencyIndicator from './currencyIndicator'
import AuthBadge from './authBadge'

export default function NavBar(props){
   
    const [drawerOpen,toggleDrawer] = useState(false)
    const location = useLocation()

    if(location.pathname.includes(ROUTES.ADMIN))
        return(

            <div style = {{position:'sticky', top:0,zIndex:2}}>
            <AppBar position='sticky' sx = {{backgroundColor: 'background.paper'}} >
                <Grid alignItems = 'center' container spacing = {2} direction = 'row' alignContent = 'center'>
                    <Grid item xs = {10}>
                    <Link sx = {{marginRight: '20px'}}underline = 'none' color = 'text.primary' variant = 'h4' component = {RouterLink} to = {ROUTES.HOME}>
                        <img alt = 'Homes-Real-Estate logo' style = {{height:'60px',alignSelf:'center'}} src = {logo}/>
                    </Link>
                    </Grid>
                    <IconButton onClick = {()=> toggleDrawer(!drawerOpen)}>
                        <MenuIcon />
                    </IconButton>
    
                </Grid>
                <Drawer open = {drawerOpen} onClose = {()=> toggleDrawer(false)}>
                        <List>
                            <ListItem onClick = {()=> toggleDrawer(false)}>
                                <ListItemText><Link color = 'text.primaryGreen' underline = 'hover' component = {RouterLink} to = {ROUTES.ADMIN}>Properties</Link></ListItemText>
                            </ListItem>
                            <ListItem onClick = {()=> toggleDrawer(false)}>
                                <ListItemText><Link color = 'text.primaryGreen' underline = 'hover' component = {RouterLink} to = {`${ROUTES.ADMIN}/${ROUTES.INQURIES}`}>Inquiries</Link></ListItemText>
                            </ListItem>
                            <ListItem onClick = {()=> toggleDrawer(false)}>
                                <ListItemText><Link color = 'text.primaryGreen' underline = 'hover' component = {RouterLink} to = {`${ROUTES.ADMIN}/${ROUTES.CMS}`}>Content Management</Link></ListItemText>
                            </ListItem>
                            
                        </List>
                    </Drawer>
                    
            </AppBar>
                <AuthBadge />
            </div>
            
        )
    
    return(
       <div style = {{position:'sticky', top:0,zIndex:2}}>
        <AppBar position='sticky' sx = {{backgroundColor: 'background.paper'}} >
            <Grid alignItems = 'center' container spacing = {2} direction = 'row' alignContent = 'center'>
                <Grid item xs = {10}>
                <Link sx = {{marginRight: '20px'}}underline = 'none' color = 'text.primary' variant = 'h4' component = {RouterLink} to = {ROUTES.HOME}>
                    <img alt = 'Homes-Real-Estate logo' style = {{height:'60px',alignSelf:'center'}} src = {logo}/>
                </Link>
                </Grid>
                <IconButton onClick = {()=> toggleDrawer(!drawerOpen)}>
                    <MenuIcon />
                </IconButton>

            </Grid>
            <Drawer open = {drawerOpen} onClose = {()=> toggleDrawer(false)}>
                    <List>
                        <ListItem onClick = {()=> toggleDrawer(false)}>
                            <ListItemText><Link color = 'text.primaryGreen' underline = 'hover' component = {RouterLink} to = {ROUTES.PROPERTIES}>Market</Link></ListItemText>
                        </ListItem>
                        <ListItem onClick = {()=> toggleDrawer(false)}>
                            <ListItemText><Link color = 'text.primaryGreen' underline = 'hover' component = {RouterLink} to = {ROUTES.SELL}>Sell</Link></ListItemText>
                        </ListItem>
                    </List>
                </Drawer>
                
        </AppBar>
            {
                location.pathname.includes(ROUTES.HOME) || location.pathname.includes(ROUTES.PROPERTIES) || location.pathname === ROUTES.ROOT?
            <CurrencyIndicator /> : null
            }
        </div>
        
    )
}

