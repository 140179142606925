import React,{createContext,useState,useEffect,useContext} from 'react'
import {auth} from './firebase'

const AuthContextT = createContext({user:null})

export function AuthContext({children}){

    

    const [user,setUser] = useState(null)

    useEffect(()=>{
        const unsubscribe = auth.onAuthStateChanged(authState=> setUser(authState))
  
    return ()=> unsubscribe();
    },[]);



    return(
        <AuthContextT.Provider value = {{
            user:user
        }} >
            {children}
        </AuthContextT.Provider>
    )
}

export function useAuth(){
    const auth = useContext(AuthContextT)

    if(auth === undefined)
        console.log(auth)

    return auth;
}