import {createContext} from 'react'

export const units = [
    {
        name: 'Acres',
        value: 1
    },
    {
        name: 'Sq ft',
        value: 43560.04
    }
]

export const LandSizeUnitContext =  createContext({
    value: units[0],
    changeUnit: ()=> {}

})