import React,{useState,useEffect,useRef} from 'react'
import {TextField,InputAdornment, Grid,Checkbox,ButtonGroup,Button,useMediaQuery,useTheme,Select,MenuItem} from '@mui/material'
import {useNavigate} from 'react-router-dom'

import * as ROUTES from '../../routes'
import Card from '../Card'
import Disclosure from '../discolsure';
import setStateAsync from '../../backend/setStateAsync'
import LoadingComponent from '../loadingComponent'
import ErrorComponent from '../errorComponent'

import {postPropertyImage, postNewProperty} from '../../backend/api'

import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import KingBedIcon from '@mui/icons-material/KingBed';
import BathroomIcon from '@mui/icons-material/Bathroom';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import parseYoutubeID from '../../backend/youtubeIDParser';
import AddImageBlock from '../addImageBlock';
import Divider from '../Divider';

export default function NewPropertyForm(props){


    const navigate = useNavigate()
    const theme = useTheme()
    const isPhone = useMediaQuery(theme.breakpoints.down('sm'))
    const [loading,toggleLoading] = useState(false)
    const [error,toggleError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [firstName,setFirstName] = useState('')
    const [lastName,setLastName] = useState('')
    const [enabled,toggleEnabled] = useState(true)
    const [listingType,setListingType] = useState('buy')
    const [propertyType,setPropertyType] = useState('residential')
    const [sold,toggleSold] = useState(false)
    const [soldPrice,setSoldPrice] = useState()
    const [featured,toggleFeatured] = useState(false)
    const [banner,toggleBanner] = useState(false)
    const [price,setPrice] = useState(0)
    const [address,setAddress] = useState('')
    const [city,setCity] = useState('')
    const [beds,setBeds] = useState(0)
    const [baths,setBaths] = useState(0)
    const [parking,setParking] = useState(0)
    const [AC,setAC] = useState(false)
    const [furnished,setFurnished] = useState(false)
    const [size,setSize] = useState(0)
    const [videoURL,setVideoURL] = useState('')
    const [VIDEOID,setVideoID] = useState('')
    const [images,setImages] = useState([])
    const [imagesToPost,setImagesToPost] = useState([])
    const [description,setDescription] = useState('')
    const mounted = useRef(true)

    useEffect(()=>{
        return()=> mounted.current = false
    },[])


useEffect(()=>{
    if(videoURL.length > 0)
        setVideoID(parseYoutubeID(videoURL) )
    
},[videoURL])




    const details = [{label:'Bedrooms',icon: <KingBedIcon />, title: 'beds',state:beds,setState: setBeds},{label:'Bathrooms',title:'baths',icon: <BathroomIcon />,state:baths,setState:setBaths},
    {label: 'Parking Spaces',title:'parking',icon: <LocalParkingIcon />,state:parking,setState:setParking},
    {label: 'Size (Acres)',title:'size',icon: <ArchitectureIcon />,state:size,setState:setSize}
]

function getCardStyle(){
    if(isPhone)
        return styles.cardDisplayPhone
    
    return styles.cardDisplay
}

function cancel(){
    navigate(`../${ROUTES.ADMIN}`)
}

function addImages(newImages){
    const tempImg = images.concat(newImages)
    const tempImagesToPost = imagesToPost.concat(newImages)
    setImages(tempImg)
    setImagesToPost(tempImagesToPost)
    
}

function removeImage(oldImage){   
    setImages(images.filter(img=> img.image !== oldImage.image))
    
}

async function saveProperty(){
    try{
        
        setStateAsync(true,toggleLoading,mounted.current)
        setStateAsync(false,toggleError,mounted.current)
        setStateAsync(false,setSuccess,mounted.current)
        const newProperty = {
            owner: {first_name: firstName, last_name: lastName, email: email, phone_number: phone},
            approved:true,enabled,price,listingType,propertyType,sold,
            soldPrice,featured,banner,address,city,description,
            beds,baths,parking,AC,furnished,size,VIDEOID,
        }
        const postedProperty = await postNewProperty(newProperty)
        await postPropertyImage(postedProperty.propertyID,imagesToPost)
        setStateAsync(true,setSuccess,mounted.current)
    }catch(e){
        setStateAsync(true,toggleError,mounted.current)
    }finally{
        setStateAsync(false,toggleLoading,mounted.current)
    }
}



if(loading)
    return(
        <LoadingComponent />
    )
if(error)
        return(
            <div style = {{width:'100%', height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <ErrorComponent  errorText = 'Error updating Property' onClick = {()=> toggleError(false)}/>
            </div>
        )
if(success){
    setTimeout(()=>{
        navigate(`../${ROUTES.ADMIN}`)
    },2000)
    return(
        <Card style = {{...getCardStyle}}>
            <h2>Property Successfully Saved!</h2>
        </Card>
    )
}
        

    return(
        <Card style = {{...getCardStyle()}}>
            <h2 style = {{textAlign:'center'}}>Post a New Property</h2>
            <Card style = {{...getCardStyle(),margin:5}}>
                <Disclosure title = {<h2 style = {{textAlign:'center',color: firstName.length === 0 || lastName.length ===0 || email.length === 0 ? 'red' : ''}}>Property Owner Details</h2>} >
                    <TextField onChange = {e=>setFirstName(e.target.value)} value = {firstName} sx = {styles.textFieldStyle} label = 'Owner First Name' title='First-Name' />
                    <TextField onChange = {e=>setLastName(e.target.value)} value = {lastName} sx = {styles.textFieldStyle} label = 'Owner Last Name' title='Last-Name' />
                    <TextField onChange = {e=>setEmail(e.target.value)} value = {email} sx = {styles.textFieldStyle} label = 'Owner Email' title='Email' />
                    <TextField onChange = {e=>setPhone(e.target.value)} value = {phone} sx = {styles.textFieldStyle} label = 'Owner Phone' title='Phone' />
                </Disclosure>
            </Card>
            <Card style = {{...getCardStyle(),margin:5}}>
                <Disclosure title = {<h2 style = {{textAlign:'center'}}>Listing Details</h2>} >
                    <Grid container align = 'center' spacing={1}>
                        <Grid item xs = {12} md = {6}>
                            <h3 style = {styles.subHeadingStyle} >Listing Type</h3>
                            <Select value = {listingType} onChange={e=> setListingType(e.target.value)} label = 'Listing Type'>
                                <MenuItem value = 'rent' >Rent</MenuItem>
                                <MenuItem value = 'buy'>Sell</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs = {12} md = {6}>
                            <h3 style = {styles.subHeadingStyle} >Property Type</h3>
                            <Select value = {propertyType} onChange={e=> setPropertyType(e.target.value)} label = 'Property Type'>
                                <MenuItem value = 'residential' >Residential</MenuItem>
                                <MenuItem value = 'commercial'>Commercial</MenuItem>
                                <MenuItem value = 'agricultural'>Agricultural</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Disclosure>
            </Card> 
            <Card style = {{...getCardStyle(),margin:5}}>
                <Disclosure title = {<h2 style = {{textAlign:'center'}}> Listing State</h2>} >
                <Grid container align = 'center' spacing={1}>
                        <Grid item xs = {12} md = {6}>
                            <h3 style = {styles.subHeadingStyle} >Property Enabled</h3>
                            <Checkbox value = {enabled} onChange={e=> toggleEnabled(e.target.checked)} />
                        </Grid>
                        <Grid item xs = {12} md = {6}>
                            <h3 style = {styles.subHeadingStyle} >Featured Property</h3>
                            <Checkbox value = {featured} onChange={e=> toggleFeatured(e.target.checked)} />
                        </Grid>
                        <Grid item xs = {12} md = {6}>
                            <h3 style = {styles.subHeadingStyle} >Cover Property</h3>
                            <Checkbox value = {banner} onChange={e=> toggleBanner(e.target.checked) } />
                        </Grid>
                        <Grid sx = {{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}} item xs = {12}>
                            <h3 style = {styles.subHeadingStyle} >Sold</h3>
                            <Checkbox value = {sold} onChange={e=> toggleSold(e.target.checked) } />
                            {sold && <TextField type = 'number' required onChange = {e=>setSoldPrice(e.target.value)} value = {soldPrice} sx = {styles.textFieldStyle} label = 'Price Sold' title='Price Sold' /> }
                        </Grid>
                    </Grid>
                </Disclosure>
            </Card>
            
            <Card style = {{...getCardStyle(),margin:5}}>
                <Disclosure title = {<h2 style = {{textAlign:'center',color: city.length === 0 || address.length ===0 ? 'red' : ''}}> <LocationOnIcon />Property Location</h2>} >
                
                    <TextField onChange = {e=>setAddress(e.target.value)} value = {address} sx = {styles.textFieldStyle} label = 'Address' title='Address' />
                    <TextField onChange = {e=> setCity(e.target.value)}value = {city} sx = {styles.textFieldStyle} label = 'City' title='City' />
                </Disclosure>
            </Card>
            <Card style = {{...getCardStyle(),margin:5}}>
            <Disclosure title = {<h2 style = {{textAlign:'center',color: size <= 0 || price <= 0 ? 'red' : ''}}> <ArchitectureIcon />Property Details</h2>} >
                
                <Grid columnSpacing={1}  align = 'center' sx = {{ px: 1}} container>
                {
                    details.map((detail,index)=>
                    <Grid key = {index} item xs = {12} md = {4} >
                    <TextField value = {detail.state} onChange = {e=> detail.setState(e.target.value)} type = 'number' InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {detail.icon}
                          </InputAdornment>
                        ),
                      }} sx = {styles.textFieldStyle} label = {detail.label} title={detail.title} />
                      </Grid>
                      )
                }
                    <Grid  item xs = {12} md = {4} >        
                        <h3 style = {styles.subHeadingStyle} >Climate Control</h3>
                        <Select value = {AC} label = 'Air Conditioned' onChange={e=>setAC(e.target.value)}>
                            <MenuItem value = {true}>Air Conditioned</MenuItem>
                            <MenuItem value = {false}>Natural Cooling</MenuItem>
                        </Select>
                    </Grid>
                    <Grid  item xs = {12} md = {4} >        
                        <h3 style = {styles.subHeadingStyle}>Furnished</h3>
                        <Select value = {furnished} label = 'Furnished' onChange={e=>setFurnished(e.target.value)}>
                            <MenuItem value = {true}>Furnished</MenuItem>
                            <MenuItem value = {false}>Unfurnished</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <TextField type = 'number' required onChange = {e=>setPrice(e.target.value)} value = {price} sx = {styles.textFieldStyle} label = {`Asking Price ${listingType === 'rent' ? '(per month)' : ''}`} title='Asking Price' /> 
                </Disclosure>
            </Card>
            <Card style = {{...getCardStyle(),margin:5}}>
                <Disclosure title = {<h2 style = {{...styles.headingStyle,color: images.length === 0 ? 'red' : ''}}><VideoLibraryIcon /> Video Tour and Images</h2>} >
                <TextField onChange = {e=> setVideoURL(e.target.value)} value = {videoURL.trim().length === 0 ? VIDEOID : videoURL} sx = {styles.textFieldStyle} label = 'Youtube Video URL' title='youtube' />
                {VIDEOID.length > 0 && 
                 <iframe width="100%" height= {200} src={`https://www.youtube.com/embed/${VIDEOID}?playlist=${VIDEOID}&loop=1&autoplay=0&rel=0&modestbranding=1`} title="Real estate video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;  picture-in-picture" allowfullscreen></iframe>}
                <Divider style = {{marginTop:10,marginBottom:10}} />
                <h2 style = {{marginTop:10,marginBottom:10,textAlign:'center'}}>Images</h2>
                <AddImageBlock images = {images} addImage = {addImages} removeImage = {removeImage} />
                </Disclosure>
            </Card>
            <Card style = {{...getCardStyle(),margin:5}}>
                <Disclosure title = {<h2 style = {{...styles.headingStyle,color: description.length === 0 ? 'red' : ''}}>Description</h2>} >
                
                <TextField  sx = {styles.textFieldStyle} value = {description} onChange = {e=> setDescription(e.target.value)} label = 'Description' title='youtube' multiline rows = {4}/>
                </Disclosure>
            </Card>
            <ButtonGroup sx = {{alignSelf:'center'}}>
                <Button disabled = {email.length === 0 || price <= 0 || size <= 0 
                                    || address.length === 0 || city.length === 0
                                    || firstName.length === 0 || lastName.length === 0} 
                onClick = {()=> saveProperty()}>Save</Button>
                <Button onClick = {()=> cancel()}>Cancel</Button>
            </ButtonGroup>
        </Card>
    )
}



const styles = {
    headingStyle:{
        textAlign:'center'
    },
    subHeadingStyle: {
        marginTop:1,
        marginBottom:1,
    },
    textFieldStyle: {
        my:2,
       // mx:5
    },
    cardDisplay:{
        display:'flex',
        flexDirection:'column',
        paddingLeft:50,
        paddingRight:50
        
    },
    cardDisplayPhone:{
        display:'flex',
        flexDirection:'column',
        paddingLeft:5,
        paddingRight:5
    }
}